<template>
  <div :class="{ 'hide-grc': hideGrc }">
    <LoggingOut v-if="isLoggingOut" />
    <IonApp>
      <ClientOnly>
        <OverlaySections />
      </ClientOnly>

      <canvas id="animations" />
      <NuxtLayout>
        <NuxtPage
          :transition="{
            name: transitionName,
          }"
        />
      </NuxtLayout>
    </IonApp>
  </div>
</template>

<script lang="ts" setup>
import { IonApp, menuController } from '@ionic/vue';
import LoggingOut from '@/shared/components/logging-out.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { uiStore } from '@/shared/pinia-store/ui';
import OverlaySections from '@/shared/components/OverlaySections.vue';
import store from '@/shared/store';
import { announcementStore } from '@/shared/pinia-store/announcement';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import { initPopovers } from 'flowbite/lib/esm/components/popover';
import { docHeight } from './shared/utils/ui';

const { isAuthenticated, hydrateUser, logoutWatcher, isEventUserRegistered, updateEventSignUp } = authStore();
const { getCurrentAnnouncement } = announcementStore();
const { handleShowNotSignedInSection } = uiStore();
const { initOnboarding } = onboardingStore();

const hideGrc = computed(() => router.currentRoute.value.name !== 'signup-invite');

const transitionName = ref('');

const {
  isLoggingOut,
  isOnboardingDay0_1: isOnboardingDay01,
  isOnboardingDay1_2: isOnboardingDay12,
  isOnboardingDay2_3: isOnboardingDay23,
  isOnboarding,
  isOnboardingDay3_end: isOnboardingDay3end,
} = authStore();

declare interface StyleData {
  onboardingShowStyle: string;
  notOnboardingShowStyle: string;
  onboardingD01ShowStyle: string;
  onboardingD12ShowStyle: string;
  onboardingD23ShowStyle: string;
  onboardingD37ShowStyle: string;
  onboardingD1HideStyle: string;
  onboardingD2HideStyle: string;
  onboardingD3HideStyle: string;
}

const styles = computed<StyleData>(() => {
  return {
    onboardingD01ShowStyle: isOnboardingDay01.value ? 'default' : 'none',
    onboardingD12ShowStyle: isOnboardingDay12.value ? 'default' : 'none',
    onboardingD23ShowStyle: isOnboardingDay23.value ? 'default' : 'none',
    onboardingD37ShowStyle: isOnboardingDay3end.value ? 'default' : 'none',
    onboardingD1HideStyle: isOnboardingDay01.value ? 'none' : 'default',
    onboardingD2HideStyle: isOnboardingDay01.value || isOnboardingDay12.value ? 'none' : 'default',
    onboardingD3HideStyle:
      isOnboardingDay01.value || isOnboardingDay12.value || isOnboardingDay23.value ? 'none' : 'default',
    onboardingShowStyle: isOnboarding.value ? 'default' : 'none',
    notOnboardingShowStyle: isOnboarding.value ? 'none' : 'default',
  };
});

const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const router = useRouter();

const calcHiddenStyles = () => {
  styles.value.onboardingD1HideStyle = isOnboardingDay01.value ? 'none' : 'default';
  styles.value.onboardingD2HideStyle = isOnboardingDay01.value || isOnboardingDay12.value ? 'none' : 'default';
  styles.value.onboardingD3HideStyle =
    isOnboardingDay01.value || isOnboardingDay12.value || isOnboardingDay23.value ? 'none' : 'default';
};

watch(dark, (value: boolean) => {
  const document = useDocument();
  document.value?.body.classList.toggle('dark', value);
});

watch(isOnboarding, (o: any) => {
  if (o) {
    styles.value.onboardingShowStyle = 'default' as string;
    styles.value.notOnboardingShowStyle = 'none' as string;
  } else {
    styles.value.onboardingShowStyle = 'none' as string;
    styles.value.notOnboardingShowStyle = 'default' as string;
  }
});

onMounted(() => {
  try {
    if (isAuthenticated.value) {
      hookEmailVerificationWS();
    }

    initPopovers();
    isEventUserRegistered.value ? updateEventSignUp(false) : initOnboarding();
    logoutWatcher();
    window.addEventListener('resize', docHeight);
    window.visualViewport?.addEventListener('resize', docHeight);
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    if (!html || !body) return;

    html.setAttribute('style', 'position:relative !important');
    body.setAttribute('style', 'position:relative !important');

    const classToAdd = dark.value ? 'dark' : '';

    document.body.classList.add(classToAdd);
  } catch (error) {}

  docHeight();

  const { currentRoute } = useRouter();
  const { getUserCharacters, getCurrencyConfig } = authStore();

  handleShowNotSignedInSection(String(currentRoute.value.name));

  if (isAuthenticated.value) {
    getUserCharacters();
    hydrateUser();
    getCurrencyConfig();
    store.dispatch('WorldsModule/getUnreadWorldsMsgs');
    store.dispatch('NotificationsModule/fetchUnreadCount');
    store.dispatch('RolechatModule/getUnreadRolechatStatus');
  }

  getCurrentAnnouncement();
});

const hookEmailVerificationWS = () => {
  const { user, authToken, hydrateUser } = authStore();

  if (user.value.is_email_verified) return;

  const {
    public: { wsUrl },
  } = useRuntimeConfig();

  const { wsConnection, wsClose } = useSocket(
    `${wsUrl}/ws/email_verification/user/${user.value.id}/?token=${authToken.value}`
  );
  wsConnection.value.onmessage = () => {
    hydrateUser();
    wsClose();
  };
};

watch(isAuthenticated, (val: boolean) => {
  const { currentRoute } = useRouter();

  handleShowNotSignedInSection(String(currentRoute.value.name));
  if (val) {
    getCurrentAnnouncement();
    isEventUserRegistered.value ? updateEventSignUp(false) : initOnboarding();
    hookEmailVerificationWS();
  }
});

useRobotsCheck();

watch(router.currentRoute, (to: any) => {
  const { setPageName } = mainStore();
  menuController.close('side');
  handleShowNotSignedInSection(to.name);
  return setPageName(to.name);
});

watch(isOnboardingDay01, (o: any) => {
  const val = o ? 'default' : ('none' as string);
  styles.value.onboardingD01ShowStyle = val;
  calcHiddenStyles();
});

watch(isOnboardingDay12, (o: any) => {
  const val = o ? 'default' : ('none' as string);
  styles.value.onboardingD12ShowStyle = val;
  calcHiddenStyles();
});

watch(isOnboardingDay23, (o: any) => {
  const val = o ? 'default' : ('none' as string);
  styles.value.onboardingD23ShowStyle = val;
  calcHiddenStyles();
});

watch(isOnboardingDay3end, (o: any) => {
  const val = o ? 'default' : ('none' as string);
  styles.value.onboardingD37ShowStyle = val;
  calcHiddenStyles();
});

const onboardingShowStyle = computed(() => styles.value.onboardingShowStyle);
const notOnboardingShowStyle = computed(() => styles.value.notOnboardingShowStyle);
const onboardingD01ShowStyle = computed(() => styles.value.onboardingD01ShowStyle);
const onboardingD12ShowStyle = computed(() => styles.value.onboardingD12ShowStyle);
const onboardingD23ShowStyle = computed(() => styles.value.onboardingD23ShowStyle);
const onboardingD37ShowStyle = computed(() => styles.value.onboardingD37ShowStyle);
const onboardingD1HideStyle = computed(() => styles.value.onboardingD1HideStyle);
const onboardingD2HideStyle = computed(() => styles.value.onboardingD2HideStyle);
const onboardingD3HideStyle = computed(() => styles.value.onboardingD3HideStyle);

useChHead();
useScripts();
</script>

<style lang="sass">
.global-onboarding
  display: v-bind(onboardingShowStyle)
  // background-color: #6D6C6C
  color: white
.global-non-onboarding
  display: v-bind(notOnboardingShowStyle)
  // background-color: #6D6C6C
  color: white
.global-onboarding-0-1
  display: v-bind(onboardingD01ShowStyle)
  background-color: #6D6C6C
  color: white
.global-onboarding-1-2
  display: v-bind(onboardingD12ShowStyle)
  background-color: #6D6C6C
  color: white
.global-onboarding-2-3
  display: v-bind(onboardingD23ShowStyle)
  background-color: #6D6C6C
  color: white
.global-onboarding-3-7
  display: v-bind(onboardingD37ShowStyle)
  background-color: #6D6C6C
  color: white
.hide-until-d1
  display: v-bind(onboardingD1HideStyle)
  background-color: #6D6C6C
  color: white
.hide-until-d2
  display: v-bind(onboardingD2HideStyle)
  background-color: #6D6C6C
  color: white
.hide-until-d3
  display: v-bind(onboardingD3HideStyle)
  background-color: #6D6C6C
  color: white
#animations
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 1111111
  pointer-events: none

.next-leave-to
  animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1)
  z-index: 0

.next-enter-to
  animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1)
  z-index: 1

.prev-leave-to
  animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1)
  z-index: 1

.prev-enter-to
  animation: enterFromLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1)
  z-index: 0

@keyframes leaveToLeft
  from
    transform: translateX(0)

  to
    transform: translateX(-25%)
    filter: brightness(0.5)

@keyframes enterFromLeft
  from
    transform: translateX(-25%)
    filter: brightness(0.5)

  to
    transform: translateX(0)

@keyframes leaveToRight
  from
    transform: translateX(0)

  to
    transform: translateX(100%)

@keyframes enterFromRight
  from
    transform: translateX(100%)

  to
    transform: translateX(0)
</style>
