import { PaginatedResponse, SocialSpace, Socials, WorldsInfo } from './../types/static-types';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';
import type { Paging } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';

export async function getWorldTraitExample(key: string) {
  const url = `/editor/examples/`;

  const { data } = await chAxios().post(url, {k: key, t: 'w'});
  return data;
}

export async function createWorld(payload: {}) {
  const { data } = await chAxios().post(`/worlds/`, payload);
  if (data.type === 'story') {
    sendAnalyticsEvent('Created story world');
  } else {
    sendAnalyticsEvent('Created social space');
  }
  return data;
}

export async function createWorldCharUserRequest(payload: {
  requestee_id: string;
  requestee_type: string;
  world: string | null | undefined;
  message: string;
}) {
  const { data } = await chAxios().post(`/worlds/requests/`, payload);

  if (payload.requestee_type === constants.requesteeAsTypes.USER) {
    sendAnalyticsEvent('Sent user request');
  }
  return data;
}

export async function createWorldChatRoom(payload: {
  title: string;
  description: string;
  banner_img_url: string;
  privacy: string;
  world: string;
}) {
  const { data } = await chAxios().post(`/worlds/chats/`, payload);
  sendAnalyticsEvent('Created space chat room');
  return data;
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getStoryWorldDraft(id?: string) {
  const url = `/worlds/drafts/${id}/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function editStoryWorldDraft(id?: string, payload?: {}) {
  const url = `/worlds/drafts/${id}/`;

  const { data } = await chAxios().patch(url, payload);
  sendAnalyticsEvent('Edited story world draft');
  return data;
}

export async function editStoryWorldDraftAuto(id: string, payload: {}) {
  const url = `/worlds/drafts/${id}/`;

  const { data } = await chAxios().patch(url, { ...payload, a: true });
  return data;
}

export async function getWorldDrafts(type: string, page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/worlds/drafts/`;

  const { data } = await chAxios().get(url, { params: { type, page, page_size: pageSize, ...params } });
  return data;
}

export async function getWorldUserCharacterRequest(world?: string, params?: {}) {
  const url = `/worlds/requests/`;

  const { data } = await chAxios().get(url, { params: { requestee_type: 'uuiduser', world_id: world, ...params } });
  return data;
}

export async function createSaveDraft(payload: {}) {
  const url = `/worlds/drafts/`;

  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Created story world draft');
  return data;
}

export async function getWorldListings(page = 1, pageSize = constants.defaultPageSize, type = 'story', params = {}) {
  const url = `/worlds/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, type, ...params } });
  return data;
}

export async function getStoryWorlds(page = 1, pageSize = constants.defaultPageSize, type = 'story') {
  const url = `/worlds/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, type } });
  return data;
}

export async function fetchUserWorlds(type = 'story', page?: number, pageSize?: number) {
  const url = `/worlds/member-of-worlds/`;

  const { data } = await chAxios().get(url, { params: { type, page, page_size: pageSize } });
  return data;
}

export async function getWorldUnreadMsgsCount() {
  const { data } = await chAxios().get(`/worlds/chats/joined-chatrooms-unread-count/`);
  return data;
}

export async function approveWorldRequest(payload: { id: string; world: string }) {
  const { data } = await chAxios().post(`/worlds/requests/request-approve/`, payload);
  sendAnalyticsEvent('Approved story world request');
  return data;
}

export async function getTopWorlds(
  page = 1,
  type = 'story',
  orderBy = '-rxn_count',
  pageSize = constants.defaultPageSize
) {
  const url = `/worlds/top/`;

  const { data } = await chAxios().get(url, { params: { page, type, order: orderBy, page_size: pageSize } });
  return data;
}

export async function getWorldMembers(params: { world_id: string | null | undefined }) {
  const url = `/worlds/requests/get-all-member-world/`;

  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getMemberRequestCounts(worldId: string | null | undefined) {
  const { data } = await chAxios().post(`/worlds/requests/social-space-req-count/`, { world: worldId });
  return data;
}

export async function getWorldCharacterMember(world_id: string | null | undefined, page?: number, pageSize?: number) {
  const url = `/worlds/requests/get-all-world-characters/`;

  const { data } = await chAxios().get(url, { params: { world_id, page, page_size: pageSize } });

  return data;
}

export async function editWorld(id?: string, worldData?: {}) {
  const url = `/worlds/${id}/`;

  const { data } = await chAxios().patch(url, worldData);
  sendAnalyticsEvent('Updated story world');
  return data;
}

export async function editWorldAuto(id?: string, worldData?: {}) {
  const url = `/worlds/${id}/`;

  const { data } = await chAxios().patch(url, { ...worldData, a: true });
  return data;
}

export async function updateWorldMemberRole(id: string, role: string) {
  const url = `/worlds/members/${id}/update-role/`;

  const { data } = await chAxios().patch(url, { role });
  sendAnalyticsEvent('Updated story world member role');
  return data;
}

export async function deleteWorld(id: string) {
  const url = `/worlds/${id}/`;

  const { data } = await chAxios().delete(url);
  sendAnalyticsEvent('Deleted story world');
  return data;
}

export async function deleteWorldDraft(id: string) {
  const url = `/worlds/drafts/${id}/`;

  const { data } = await chAxios().delete(url);
  sendAnalyticsEvent('Deleted story world draft');
  return data;
}

export async function getWorld(id?: string) {
  const url = `/worlds/${id}/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getUserStoryWorld(
  user?: string,
  type = 'story',
  page = 1,
  pageSize = constants.defaultPageSize,
  role?: string | null,
  params = {}
) {
  const url = `/worlds/user-worlds/`;

  const { data } = await chAxios().get(url, {
    params: { user, type, page, page_size: pageSize, ...(role && { role }), ...params },
  });
  return data;
}

export async function getMyCreationsUserStoryWorlds(user?: string) {
  const url = `/worlds/user-worlds/`;
  const cacheKey = `my-creations-worlds`;

  const value = await useChFetch<PaginatedResponse<WorldsInfo[]>>(
    cacheKey,
    url,
    { user, type: 'story', page: 1, page_size: constants.defaultPageSize },
    30000 * 60
  );

  return value;
}

export async function getUserStoryWorldByUsername(
  name: string,
  type = 'story',
  page = 1,
  pageSize = constants.defaultPageSize,
  role?: string
) {
  const url = `/worlds/username-worlds/`;

  const { data } = await chAxios().get(url, {
    params: { user: name, type, page, page_size: pageSize, ...(role && { role }) },
  });
  return data;
}

export async function leaveSocialSpace(id: string) {
  const url = `/worlds/${id}/leave-social-space/`;
  const { data } = await chAxios().delete(url);
  sendAnalyticsEvent('Left Social Space');
  return data;
}

export async function getAllStoryWorlds(
  type = 'story',
  user: string,
  page = 1,
  pageSize = constants.defaultPageSize,
  incl_u_m: boolean | null = null
) {
  const url = `/worlds/`;

  const { data } = await chAxios().get(url, { params: { type, user, page, page_size: pageSize } });
  return data;
}

export async function getOwnStoryWorlds(type = 'story', page = 1, pageSize = constants.defaultPageSize) {
  const url = `/worlds/own/`;

  const { data } = await chAxios().get(url, { params: { type, page, page_size: pageSize } });
  return data;
}

export async function getMemberstatus(params: { world_id?: string; room_id?: {} }) {
  const url = `/worlds/requests/status/`;

  const { data } = await chAxios().get(url, { params });
  return data;
}
export async function declineWorldRequest(id: string) {
  const { data } = await chAxios().delete(`/worlds/requests/${id}/`);
  sendAnalyticsEvent('Declined story world request');
  return data;
}

export async function removeWorldMember(id: string) {
  const { data } = await chAxios().delete(`/worlds/members/${id}/remove-world-member/`);
  sendAnalyticsEvent('Removed story world member');
  return data;
}

export async function leaveWorld(id: string | null | undefined) {
  const { data } = await chAxios().delete(`/worlds/${id}/leave/`);
  sendAnalyticsEvent('Left story world');
  return data;
}

export async function getWorldsFromSlug(slug: string) {
  const url = `/worlds/from-slug/`;

  const { data } = await chAxios().get(url, { params: { slug } });
  return data;
}

export async function getWorldRoom(privacy: string, world: string, page?: number) {
  const url = `/worlds/chats/`;

  const { data } = await chAxios().get(url, { params: { world, privacy, page } });
  return data;
}

export async function sendWorldchatMessage(roomId: string, messageData: any) {
  const { data } = await chAxios().post(`/worlds/chats/${roomId}/send-message/`, messageData);
  sendAnalyticsEvent('Sent message');
  return data;
}

export async function editWorldRoomDetails(
  id: string,
  roomData: {
    title: string;
    description: string;
    banner_img_url: string;
  }
) {
  const url = `/worlds/chats/${id}/`;

  const { data } = await chAxios().patch(url, roomData);
  sendAnalyticsEvent('Updated space chatroom');
  return data;
}

export async function getWorldsReactions(type = 'story', page = 1, pageSize = constants.defaultPageSize) {
  const url = `/worlds/reacted/`;

  const { data } = await chAxios().get(url, { params: { type, page, page_size: pageSize } });
  return data;
}

export async function getWorldRoomChatMsgs(roomId: string, page = 1, pageSize = 100) {
  const url = `/worlds/chats/${roomId}/message/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
  return data;
}

export async function markWorldRoomMsgsRead(roomId: string) {
  const { data } = await chAxios().patch(`/worlds/chats/${roomId}/mark-all-read/`);
  return data;
}

export async function getWorldchatRoomDetails(roomId: string) {
  const { data } = await chAxios().get(`/worlds/chats/${roomId}/`);
  return data;
}

export async function getWorldsMemberCounts(worldIds: string[]) {
  const { data } = await chAxios().post(`/worlds/members/member-counts/`, { world_ids: worldIds });
  return data;
}

export async function getSocialSpaceRoomsCount(worldIds: string[]) {
  const { data } = await chAxios().post(`/worlds/members/room-count/`, {
    world_ids: worldIds,
  });
  return data;
}

export async function getWorldsUnreadCounts(worldIds: string[]) {
  const url = `/worlds/chats/world-unread-count/`;

  const { data } = await chAxios().post(url, { world_ids: worldIds });
  return data;
}

export async function getWorldRoomUnreadCounts(roomIds: string[]) {
  const url = `/worlds/chats/room-unread-count/`;

  const { data } = await chAxios().post(url, { room_ids: roomIds });
  return data;
}

export async function getProfileWorlds(userId: string, page = 1) {
  const url = `/worlds/profile/`;
  const { data } = await chAxios().get<PaginatedResponse<WorldsInfo[]>>(url, {
    params: {
      page,
      user: userId,
      type: 'story',
    },
  });

  return data;
}

export async function getProfileSocialSpaces(userId: string, page = 1) {
  const url = `/worlds/profile/`;
  const { data } = await chAxios().get<PaginatedResponse<SocialSpace>>(url, {
    params: {
      page,
      user: userId,
      type: 'socialspace',
    },
  });

  return data;
}
