import { defineStore, storeToRefs } from 'pinia';
import { CharacterStylize, CharacterTemplate, Paging } from './../types/static-types';
import { toast } from '@/shared/native/toast';
import { authStore } from './auth';
import {
  applyCharacterStylizeTemplate,
  getCharTemplate,
  getCharacterStylizeEntities,
  getCharacterStylizeTemplate,
  updateCharTemplate,
} from '@/shared/actions/characters';
import { getNextPage } from '@/shared/helpers/pagination';
import { getVirtualItemDetails } from '../actions/virtualItems';

export interface ICharacterStylizeState {
  currentCharacterStylize: CharacterStylize | null;
  currentCharacterTemplate: CharacterTemplate[];
  isLoading: boolean;
  isDeletingAppliedCustomizationTemplate: boolean;
  tempPaging: Paging | null;
  currentTemplateData: any;
  previousTemplate: any;
  previousCustomize: any;
}

const useCharacterStylize = defineStore('character-stylize', {
  state: (): ICharacterStylizeState => ({
    currentCharacterStylize: null,
    currentCharacterTemplate: [],
    isLoading: false,
    isDeletingAppliedCustomizationTemplate: false,
    currentTemplateData: {},
    tempPaging: null,
    previousTemplate: {},
    previousCustomize: {},
  }),
  actions: {
    deleteAppliedCustomizationTemp(value: any) {
      this.isDeletingAppliedCustomizationTemplate = value;
    },
    async loadTemplate(id: string) {
      const temp = await getCharTemplate(id);
      this.currentTemplateData = temp;
      // if (isEmpty(get(this.currentCharacterStylize, 'template_applied')) && get(this.routeName, 'tempId')) {
      //   this.currentCharacterStylize!.data = cloneDeep(this.currentTemplateData.data);
      // }
      //   this.currentCharacterStylize!.template_applied = temp.id;
    },
    async loadCharacterStylize(idAsString: string | undefined, type: string) {
      try {
        this.isLoading = true;
        const character = await getCharacterStylizeEntities(idAsString, type);
        this.currentCharacterStylize = character;
        this.currentTemplateData.data = cloneDeep(this.currentCharacterStylize?.data);
        this.currentTemplateData.template_applied = this.currentCharacterStylize?.template_applied;
        if(get(this.currentTemplateData.data,'background_overlay') && get(this.currentTemplateData.data,'background_overlay.id')){
          const { extra, title } = await getVirtualItemDetails(get(this.currentTemplateData.data,'background_overlay.id'))
          this.currentTemplateData.data.background_overlay = {...this.currentTemplateData.data.background_overlay,extra,title}
            if (isEmpty(get(this.currentTemplateData.data, 'background_overlay.extra'))) {
            this.currentTemplateData.data.background_overlay.extra = {
              ...this.currentTemplateData.data.background_overlay.extra,
              bgRepeat: true,
            };
            }
        }
      } catch (error) {
        toast.show('Error loading ', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    async loadCharacterTemplate(searchText: any = null) {
      try {
        this.isLoading = true;
        if (!isEmpty(searchText)) {
          const { results, ...paging } = await getCharacterStylizeTemplate(1, 5, { search: searchText });
          this.currentCharacterTemplate = results;
          this.tempPaging = paging;
        } else {
          const { results, ...paging } = await getCharacterStylizeTemplate(1);
          this.currentCharacterTemplate = results;
          this.tempPaging = paging;
        }
      } catch (error) {
        toast.show('Error loading template', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    async requestLoadMoreTemplate() {
      try {
        const { results, ...paging } = await getNextPage(this.tempPaging!);
        this.currentCharacterTemplate = this.currentCharacterTemplate.concat(results);
        this.tempPaging = paging;
      } catch (e) {}
    },
    changeTemplateKey(value: any) {
      this.currentCharacterStylize!.template_applied = value;
    },
    changeThemeBackgroundsKey(key: any, value: any) {
      this.currentTemplateData.data.theme.backgrounds[key] = value;
    },
    changeThemeTextKey(mainKey: any, key: any, value: any) {
      if (!this.currentCharacterStylize) return;
      this.currentTemplateData.data.theme.text[mainKey][key] = value;
    },
    changeCharacterCustomizationData(key:any,value:any){
      if (!this.currentCharacterStylize) return;
      this.currentTemplateData.data[key] = value;    
    },
    async previousCustomizeandTempData() {
      const router = useRouter();
      const { previousCustomize, previousTemplate } = authStore();
      this.previousCustomize = previousCustomize.value;
      this.previousTemplate = previousTemplate.value;
      const clone =
        get(this.currentCharacterStylize, 'template_applied') && get(router.currentRoute.value.query, 'tempId')
          ? cloneDeep(this.previousTemplate.data)
          : cloneDeep(this.previousCustomize.data);
      this.previousCustomize.isTempEmp
        ? (this.currentTemplateData.data = cloneDeep(this.previousTemplate.data))
        : (this.currentTemplateData.data = clone);

      const appliedTemp = this.previousCustomize.isTempEmp
        ? null
        : get(this.currentCharacterStylize, 'template_applied')
        ? get(this.currentCharacterStylize, 'template_applied')
        : get(router.currentRoute.value.query, 'tempId');
      const payload = {
        entity_id: this.currentCharacterStylize?.entity_id,
        entity_type: get(this.currentCharacterStylize, 'entity.type'),
        data: this.previousCustomize.data,
        applied_template: appliedTemp,
      };

      const payload1 = {
        data: this.previousTemplate.data,
      };

      const temp_id =
        (get(this.currentCharacterStylize, 'template_applied') && get(router.currentRoute.value.query, 'tempId')) ||
        get(router.currentRoute.value.query, 'tempId')
          ? get(router.currentRoute.value.query, 'tempId')
          : get(this.currentCharacterStylize, 'template_applied');

      applyCharacterStylizeTemplate(payload);
      await updateCharTemplate(temp_id as string, true, payload1);
    },
    async previousTemplateData() {
      const { previousTemplate } = authStore();
      this.previousTemplate = previousTemplate.value;
      this.currentTemplateData.data = cloneDeep(this.previousTemplate.data);
    },
    async changeTemplateName(value: string = '') {
      this.currentTemplateData.name = value;
    },
    clearAll() {
      this.currentTemplateData = {};
    },
    changeStylesKey(mainKey: any, key: any, value: any) {
      if (!this.currentCharacterStylize) return;
      if (isEmpty(key)) {
        this.currentTemplateData.data.theme.styles[mainKey] = value;
      } else {
        this.currentTemplateData.data.theme.styles[mainKey][key] = value;
      }
    },
  },
  getters: {
    customizeCharacter(): CharacterStylize | null {
      return this.currentCharacterStylize;
    },
    currentTemplate(): any {
      return this.currentTemplateData;
    },
    prevTemplate(): any {
      const { previousTemplate } = authStore();
      return previousTemplate.value;
    },
    prevCustomize(): any {
      const { previousCustomize } = authStore();
      return previousCustomize.value;
    },
    template(): CharacterTemplate[] | null {
      return this.currentCharacterTemplate;
    },
    paging(): Paging | null {
      return this.tempPaging;
    },
    loading(): boolean {
      return this.isLoading;
    },
    deletingAppliedCustomizationTemplate(): boolean {
      return this.isDeletingAppliedCustomizationTemplate;
    },
  },
});

export const characterStylizeStore = () => {
  const store = useCharacterStylize();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
