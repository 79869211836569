<template>
  <div v-if="product" class="product-listing clickable-item-hov" @click="onCardPress">
    <div class="ml-1 mt-1 product-header">
      <h4 class="no-select product-name">
        {{ product.name }}
        <div v-if="product.is_new" class="is-new-badge">
          <div class="tag d-flex-center no-select">New</div>
        </div>
      </h4>
    </div>

    <div class="ml-1 image-area position-relative d-flex align-items-center">
      <div v-if="imagesFromProductFeatureTuples?.length" class="product-images mt-3">
        <div v-for="(isrc, index) in imagesFromProductFeatureTuplesTruncated" class="product-image">
          <div class="position-relative">
            <CurrencyImage v-if="isrc === '/hubuck.svg'" :type="'coin'" />
            <img :src="isrc" class="no-select" />
          </div>
          <span v-if="quantitiesFromProductFeatureTuples[index]" class="quantity"
            >x{{ formatNumber(quantitiesFromProductFeatureTuples[index], false) }}</span
          >
          <ion-badge
            v-if="index === 2 && imagesFromProductFeatureTuples.length > imagesFromProductFeatureTuplesTruncated.length"
            color="secondary"
            class="more no-select"
          >
            <span class="no-select more-inner"
              >+{{ imagesFromProductFeatureTuples.length - imagesFromProductFeatureTuplesTruncated.length }}</span
            >
          </ion-badge>
        </div>
      </div>
    </div>

    <div class="button-area mr-2">
      <ion-button class="price-button" @click.stop="tryStartPurchase">
        <div class="button-inner" />
        <span v-if="!product.amount_gems && !product.amount" class="price-text">Free</span>
        <span v-else-if="product.amount" class="price-text"
          >${{ ((product.discounted_amount || product.amount) / 100).toFixed(2) }}</span
        >
        <span v-else-if="product.amount_gems" class="price-text">
          <div class="position-relative">
            <inline-svg v-if="product?.amount_gems" src="/gem.svg" class="svg mr-1" />
          </div>
          <span class="amount">{{ formatNumber(product?.amount_gems) }}</span>
        </span>
        <div v-if="product.discount_percent" class="discount-badge">
          <div class="tag d-flex no-select">{{ product.discount_percent }}% Off</div>
        </div>
        <div v-if="product.limit_per_user" class="limit-badge">
          <small>{{ product.limit_per_user }} per user</small>
        </div>
      </ion-button>
      <div class="timer-badge-container" v-if="itemInStoreTimer">
        <ion-badge class="timer-badge">
          <vue-countdown
            v-slot="{ days, hours, minutes, seconds }"
            :time="formatFromNowInMilliseconds(itemInStoreTimer)"
            @end="reloadListings"
          >
            <i class="ti-time mr-1 time-icon" />
            <span class="timer">
              <span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
              ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
            </span>
          </vue-countdown>
        </ion-badge>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { openBuyWithGemsModal, openPurchaseModal, openSpecialBundleShopModal } from '@/shared/utils/modals';
import { authStore } from '@/shared/pinia-store/auth';
import { StoreProduct } from '@/shared/types/static-types';
import { COMPLIMENT_BOMB, CANCEL } from '@/shared/statics/constants';
import { modalController } from '@ionic/vue';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { useImageTuple } from '~/composables/useImageTuple';
import { useShopProductsFeature } from '~/composables/useShopProductsFeature';
import PurchaseSuccessModal from '@/shared/modals/PurchaseSuccessModal.vue';
import SiteHtmlModal from '@/shared/modals/SiteHtmlModal.vue';
import CurrencyImage from '@/shared/components/CurrencyImage.vue';

const { usersCurrency, getUsersCurrency } = authStore();

const props = defineProps({
  product: {
    type: Object as PropType<StoreProduct>,
  },
});
const emits = defineEmits(['reload']);
const product = toRef(props, 'product');
const route = useRoute();
const router = useRouter();

const modalOpen = ref(false);
const { productFeatureTuples, quantitiesFromProductFeatureTuples } = useShopProductsFeature(product.value);

const imagesFromProductFeatureTuples = computed(() => {
  const extraImages = product.value?.more_preview_images || ([] as string[]);

  const imgs = productFeatureTuples.value
    .map((tup: any[]) => {
      const { imageFromTuple } = useImageTuple(tup);
      return imageFromTuple();
    })
    .filter((img: any) => img) as string[];

  const out = [...imgs, ...extraImages];
  return out;
});

const imagesFromProductFeatureTuplesTruncated = computed(() => {
  const out = imagesFromProductFeatureTuples.value;
  return out.slice(0, 3);
});

const itemInStoreTimer = computed(() => {
  const discountedUntil = get(product.value, 'discounted_until');
  const expiresIn = get(product.value, 'expires_at');
  return discountedUntil ? new Date(discountedUntil) : expiresIn ? new Date(expiresIn) : null;
});

const reloadListings = () => {
  emits('reload');
};
const code = computed(() => {
  return get(route.query, 'bundle');
});

const characterSlug: any = computed(() => {
  return get(route.query, 'redirectCharSlug');
});

const onCardPress = async (ev: any) => {
  if (product.value?.description_html || product.value?.description) {
    const res = (await openDescriptionModal(ev)) as any;
    if (res === 'continue') tryStartPurchase(ev);
  } else {
    tryStartPurchase(ev);
  }
};

const openBundleShop = (ev: MouseEvent) => {
  const { isAuthenticated } = authStore();
  if (!isAuthenticated.value) return;
  openSpecialBundleShopModal(ev);
};

const tryStartPurchase = (ev: any) => {
  modalOpen.value = true;
  if (product.value?.amount_gems) {
    if (usersCurrency.value.gems >= get(product.value, 'amount_gems')!) {
      startPurchase(ev);
    } else {
      openBundleShop(ev);
    }
  }
  if (product.value?.amount) startPurchase(ev);
};

const startPurchase = (ev: any) => {
  if (product.value?.amount_gems) {
    startPurchaseWithGems(ev);
  } else if (product.value?.amount_coins) {
    alert('Coming soon!');
  } else {
    startPurchaseWithFiat(ev);
  }
};

const formatNumber = (amount?: number, keepCommas = true) => {
  if (!amount) return;
  if (amount >= 1000000) {
    return (Math.floor(amount / 10000) / 100).toFixed(2) + 'M';
  } else {
    const x = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return keepCommas ? x : x.replace(/,/g, '');
  }
};

const startPurchaseWithGems = async (event: any) => {
  const productCode = product.value?.code;
  const quantity = product.value?.quantity || 1;
  if (!productCode) return;
  await getUsersCurrency();
  const currentRouteName = String(route.name);
  const modalDismissRes = (await openBuyWithGemsModal(event, product.value)) as any;
  if (modalDismissRes?.data === COMPLIMENT_BOMB) {
    // thing to do in the background while the post-purchase modal is open
    const action = (await openPurchaseSuccessModal()) as any;
    if (action == 'inventory') {
      return router.push({ name: 'home', query: { modal: 'compliment-bomb-hub' } });
    }
  } else if (modalDismissRes?.data && product.value?.post_action) {
    (await openPurchaseSuccessModal()) as any;
  }
};

const startPurchaseWithFiat = async (event: any) => {
  const productCode = product.value?.code;
  const quantity = product.value?.quantity || 1;
  if (!productCode) return;

  const currentRouteName = String(route.name);
  const openedFrom = `${currentRouteName}-buy-${productCode}-${quantity}`;
  const modalDismissRes = (await openPurchaseModal(
    event,
    productCode,
    product.value?.name || '',
    quantity,
    product.value?.post_action,
    openedFrom,
    product.value?.first_purchase_offer
  )) as any;

  if (
    modalDismissRes.role === CANCEL &&
    modalDismissRes.data !== code.value &&
    characterSlug.value &&
    modalOpen.value &&
    code.value
  ) {
    router.replace({ name: 'character-profile-new', params: { slug: characterSlug.value } });
  }
  if (modalDismissRes?.data === COMPLIMENT_BOMB) {
    // thing to do in the background while the post-purchase modal is open
    const action = (await openPurchaseSuccessModal()) as any;
    if (action == 'inventory') {
      return router.push({ name: 'home', query: { modal: 'compliment-bomb-hub' } });
    }
  } else if (modalDismissRes?.data && product.value?.post_action) {
    if (product.value?.post_action?.includes('amethyst') || product.value?.post_action?.includes('hubux')) {
      // update hubux and amethyst count for user
      await getUsersCurrency();
    }
    (await openPurchaseSuccessModal()) as any;
  }
};

const openPurchaseSuccessModal = async () => {
  if (!product.value) return;
  if (product.value?.discounted_until || product.value?.expires_at || product.value?.first_purchase_offer) {
    reloadListings();
  }
  const modal = await modalController.create({
    backdropDismiss: false,
    component: PurchaseSuccessModal,
    cssClass: 'purchase-success-modal',
    componentProps: {
      postAction: product.value.post_action,
      productCode: product.value.code,
      firstPurchaseOffer: product.value?.first_purchase_offer,
    },
  });
  modal.onDidDismiss().then((value) => {
    if (value.role === CANCEL && characterSlug.value && code.value) {
      router.replace({ name: 'character-profile-new', params: { slug: characterSlug.value } });
    }
  });

  const action = await modal.present();
  return action;
};

watch(
  product,
  () => {
    if (product.value!.code === code.value && !modalOpen.value && !get(route.query, 'km_')) {
      tryStartPurchase('');
    }
  },
  { immediate: true }
);

const openDescriptionModal = async (ev: any) => {
  if (!product.value) return;
  const cprops = {
    productName: product.value.name,
    htmlContent: product.value.description_html || product.value.description,
  } as any;
  if (product.value.amount_gems) {
    cprops.productPurchasableGems = product.value;
  } else if (product.value.amount) {
    cprops.productPurchasable = product.value;
  }
  const modal = await modalController.create({
    component: SiteHtmlModal,
    cssClass: 'modal-big md modal-default show-modal',
    componentProps: cprops,
  });
  const action = await modal.present();
  modal
    .onDidDismiss()
    .then((res: any) => {
      try {
        if (res?.data === 'continue') {
          startPurchase(ev);
        }
      } catch (e) {}
    })
    .catch(() => {});
  return action;
};
</script>

<style lang="sass" scoped>
.discount-badge
  position: absolute
  user-select: none
  width: 55px
  bottom: -27px
  .tag
    background-color: #FF2D5A
    font-size: 12px
    padding-left: 4px
    padding-top: 1px
.limit-badge
  position: absolute
  user-select: none
  width: 100px
  bottom: -42px
  color: #214163 !important
  small
    font-size: 11px
.is-new-badge
  margin-left: 0.25rem
  display: inline-block
  position: relative
  width: 52px
  color: #FF2D5A
  z-index: 1
  user-select: none
.amount
  font-weight: bold
  @media(max-width:560px)
    font-size: 12px
  @media(max-width:380px)
    font-size: 12px !important
  .dark .amount
    color: white
.rest
  font-weight: bold
  width: 100%
.tag
  padding-left: 10px
  padding-right: 10px
  height: 20px
  border: 2px solid #FF2D5A
  font-size: 14px
  line-height: 14px
  font-weight: bold
  border-radius: 12px
img
  height: 100px !important
  object-fit: contain
  z-index: 1
  width: 100%
.product-listing
  position: relative
  display: flex
  overflow: visible !important
  --overflow: visible !important
  justify-content: space-between !important
  align-items: center !important
  width: 100%
  height: 120px
  max-width: 100%
  border-radius: 12px
  border: 3px solid rgb(245, 235, 255)
  box-shadow: 0px 4px 0px 0px rgba(141, 112, 208)
  overflow: hidden
  background-color: rgba(234, 219, 255, 1)
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(to top, rgba(255, 164, 240, 0) 0%, rgba(255, 200, 246, 0.5) 100%)
    z-index: 0
  .image-area
    position: relative
    margin-top: 0.5rem
    img
      height: 150px
      aspect-ratio: 1
    @media (max-width: 419px)
      img
        height: max(15vw, 70px)
        width: max(15vw, 70px)
        min-width: max(15vw, 70px)
        aspect-ratio: 1 !important
.product-images
  position: relative
  display: flex
  align-items: center !important
  height: 100%
  max-width: 100%
  min-height: 80px !important
  max-height: 80px !important
  min-width: 70px
  width: 100% !important
  height: 100% !important
  @media (max-width: 419px)
    min-height: 22vw !important
    max-height: 22vw !important
    max-width: 22vw !important
.product-image
  position: relative
  display: flex
  margin: auto 0.5rem auto 0
  img
    border-radius: 16px
    background-color: rgba(255, 255, 255, 0.5)
    margin: auto 0
    padding: 0.2rem
    min-height: 80px !important
    max-height: 80px !important
    width: 80px
    height: 100% !important
    aspect-ratio: 1 !important
    @media (max-width: 419px)
      min-height: 70px !important
      max-height: 70px !important
      width: 80px
      width: 100% !important
      height: 100% !important
      aspect-ratio: 1 !important
.product-image:before, .product-image::after
  content: ""
  position: absolute
  border: none
  border-radius: 16px
.price-button
  --background: linear-gradient(90deg, rgb(177,77,211) 20%, #4155C9 100%)
  --border-radius: 12px
  width: 6rem
  height: 2.8rem
  --overflow: show !important
  @media (max-width: 419px)
    width: 4rem
    height: 2.1rem
.price-text
  font-size: 18px
  font-weight: bold
  color: white
  user-select: none !important
  @media (max-width: 419px)
    font-size: 14px
.product-header
  position: absolute
  top: -0.25rem
  left: -0.25rem
  width: 100%
.product-name
  color: #431C89
  display: flex
  font-size: 18px
  font-weight: bold
  user-select: none !important
  margin: 0 // Remove bottom margin
  position: absolute // Position absolutely
  top: 0.25rem // Adjust top position
  left: 0.25rem // Adjust left position
  @media (max-width: 419px)
    font-size: 16px
    line-height: 16px
.product-description
  color: #431C89
  font-size: 14px
  margin-top: 0
  @media (max-width: 419px)
    font-size: 10px
    line-height: 11px
  @media (max-width: 576px)
    font-size: 12px
    line-height: 13px
.button-area
  display: flex-column
  align-self: center
  justify-self: center
  margin: 0 0.75rem 0 auto !important
  @media (max-width: 576px)
    margin: 0 0.25rem !important
.button-inner
  pointer-events: none
  background-color: rgba(255, 255, 255, 0.2)
  position: absolute
  width: 4rem
  height: 14px
  top: -2px
  border-radius: 12px
  z-index: -1
  margin: 0
  padding: 0
  @media (max-width: 419px)
    width: 2.8rem
    height: 8px
    top: -6px
    margin: 0
    padding: 0
.icon
  z-index: 2
  font-size: 1.5rem
  color: #431C89
  cursor: pointer
  @media (max-width: 576px)
    font-size: 1.25rem
    margin-top: 0.25rem
.more-inner
  font-size: 1rem
.more
  position: absolute
  right: -1.3rem
  top: 85%
  transform: translate(50%, -50%)
  z-index: 2
  border-radius: 8px
.quantity
  position: absolute
  right: 3px
  bottom: 0
  z-index: 1
  font-family: 'Roboto'
  font-weight: 900
  font-size: 1.25rem
  background: linear-gradient(90deg, rgba(255, 250, 234, 1), rgba(255, 212, 73, 1))
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  background-clip: text
  text-fill-color: transparent
  -webkit-text-stroke-width: 1px
  -webkit-text-stroke-color: rgba(67, 28, 137, 1)
  @media (max-width: 419px)
    font-size: 1rem
    -webkit-text-stroke-width: 1px
    -webkit-text-stroke-color: rgba(67, 28, 137, 0.75)
.timer-badge-container
  display: flex
  align-items: center
  margin-left: auto
  margin-right: auto
  justify-content: center
  --overflow: visible !important
  overflow: visible !important
.timer-badge
  padding: 0.12rem 0.5rem
  display: flex
  align-items: center
  justify-content: center
  background: linear-gradient(to right, #FF004D, #EE4035)
  border-radius: 7px
  position: absolute
  top: 10px
</style>
