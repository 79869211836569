import { UserProfile } from './../types/static-types';
import { User } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';
import constants from '@/shared/statics/constants';

export async function getSelf() {
  const url = `/users/self/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function onCloseUsersPayment() {
  const url = `/users/payment-modal-closed/`;
  const { data } = await chAxios().put(url);
  return data;
}


export async function usersFreebie() {
  const url = `/users/freebie-box/`;
  const { data } = await chAxios().get(url);
  return data;
}
export async function usersCurrency() {
  const url = `/users/my-earnings/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getUser(userId: string) {
  const url = `/users/${userId}/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getUserContentWarning(username: string) {
  const url = `/users/content-warning/`;
  const { data } = await chAxios().get(url, { params: { username } });
  return data;
}

export async function emailVerification(token: any, userIdentifier?: string) {
  const url = `/users/email-confirmation/`;

  const { data } = await chAxios().post(url, { token, username: userIdentifier });
  return data;
}


export async function transferUserGems(payload: {}) {
  const url = `/users/transfer-gems/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}

export async function receiveGemsResponse(payload: {}) {
  const url = `/users/receive-gems-response/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}




export async function providerFeeInformation() {
  const url = `/users/fee-details/
`;

  const { data } = await chAxios().get(url);
  return data;
}



export async function emailUpdate(new_email: any) {
  const url = `/users/update-email/`;

  const { data } = await chAxios().post(url, { new_email });
  return data;
}

export async function updateContentPreferences(payload: {}) {
  const url = `/users/content-preferences/`;

  const { data } = await chAxios().patch(url, { ...payload });
  return data;
}

export async function resendEmail() {
  const url = `/users/resend-email/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getUserPro() {
  const url = `/users/pro-features/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getUserByUsername(username: string) {
  const { data } = await chAxios().get(`/users/by-username/`, { params: { username } });
  return data;
}
export async function getUserCharStatus() {
  const { data } = await chAxios().get(`/users/user-status/`);
  return data;
}

export async function getUserProfileById(id: string) {
  const { data } = await chAxios().get(`/user-profiles/${id}/`);
  return data;
}

export async function getUserProfileByUsername(username: string) {
  const cacheKey = `user-profile-${username}`;
  const value = await useChFetch<UserProfile>(cacheKey, `/user-profiles/username/`, { user: username });
  // const { data } = await chAxios().get(`/user-profiles/username/?user=${username}`);
  return value;
}

export async function getUsers(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/users/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
  return data;
}
export async function getGiftUsers(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/users/to-gift/`;

  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
  return data;
}

export async function getMyInviteCode() {
  const { data } = await chAxios().get(`/users/invite-code/`);
  return data;
}

export async function updateUserdata(userId: string, userData: Partial<User>) {
  const url = `/users/${userId}/`;

  const { data } = await chAxios().patch(url, userData);
  return data;
}

export async function updateUserProfile(id: string, profile: Partial<UserProfile>) {
  const url = `/user-profiles/${id}/`;

  const { data } = await chAxios().patch(url, profile);
  return data;
}

export async function getOwnInventory(pageSize: number = 20) {
  const url = `/user-profiles/inventory-all/?page_size=${pageSize}`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getUsernameColors() {
  const url = `/custom-colors/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getPublicInventory(id: string) {
  const url = `/user-profiles/inventory/?id=${id}`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function setPublicInventory(ids: string[]) {
  const url = `/user-profiles/feature/`;

  const { data } = await chAxios().post(url, { ids });
  return data;
}

export async function getOwnVisualCollections(params: { page?: number; page_size?: number }) {
  const url = `/image-collections/own/`;

  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function enterGiveaway(code: string) {
  const url = `/collectibles/giveaway/`;

  const { data } = await chAxios().post(url, { code });
  return data;
}

export async function partialLookup(query = '', page = 1) {
  const url = `/users/lookup/?query=${query.trim()}&page=${page}`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getSuspensionStatus() {
  const url = `/users/suspension-status/`;
  const { data } = await chAxios().get(url);
  return data;
}
