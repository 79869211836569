<template>
  <div class="sections" v-auto-animate>
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
    <div class="onboarding" v-if="showOnboarding && showOverlay">
      <!-- <div v-if="currentStep === 24">
        <div id="o-arrow" class="o-arrow" />
        <div class="msg o-disable d-flex p-2">
          <div>“Help us make the site&nbsp;<span class="color-pink">safer for everyone</span>!”</div>

          <img class="ml-2" src="/onboarding/hub-c.svg" />

          <div class="cta">Click on one of the options</div>
        </div>
      </div>

      <div v-if="currentStep === 25">
        <div id="o-arrow" class="o-arrow" />
        <div class="msg o-disable d-flex p-2 top">
          <div>“Could you tell us your&nbsp;<span class="color-pink">age range </span>as well?”</div>

          <img class="ml-2" src="/onboarding/hub-c.svg" />

          <div class="cta">Click on one of the options</div>
        </div>
      </div> -->

      <!-- <div v-if="currentStep === 27">
        <div id="o-arrow" class="o-arrow" />
        <div class="msg o-disable d-flex p-2" :class="{ 'push-up': !isDesktopSize }">
          <div>“Awesome! Let’s get back to the&nbsp;<span class="color-pink">Home Screen</span>.”</div>

          <img class="ml-2" src="/onboarding/hub-c.svg" />

          <div class="cta">Click on the home icon</div>
        </div>
      </div> -->

      <div v-if="currentStep === 21">
        <div class="backdrop" />
        <div @click="stepForward" class="msg d-flex p-2">
          <div>
            “Now feel free to explore the site!&nbsp;<span class="color-pink"
              >I’ll be back in 22 hours to unlock Challenges and Events</span
            >.
          </div>

          <img class="ml-2" src="/onboarding/hub-c.svg" />

          <div class="cta">Tap to close</div>
        </div>
      </div>
    </div>

    <div v-if="isMyCreationsOpen" class="my-creations-overlay" @click="toggleMyCreations">
      <div class="flexer">
        <router-link to="/my-creations?tab=characters">
          <div class="selection selection--one animated bounceInUp" @click="character">
            <div class="d-flex align-items-center gap-2">
              <inline-svg width="33px" src="/icons/c-ch.svg" />
              <div class="text">My Characters</div>
            </div>
          </div>
        </router-link>

        <router-link to="/drafts">
          <div class="selection selection--nine animated bounceInUp" @click="character">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-pencil-alt" />
              <div class="text">My Drafts</div>
            </div>
          </div>
        </router-link>

        <router-link to="/my-creations?tab=worlds">
          <div class="selection selection--two animated bounceInUp" @click="world">
            <div class="d-flex align-items-center gap-2">
              <inline-svg width="41px" src="/icons/c-st.svg" />
              <div class="text">My Story Worlds</div>
            </div>
          </div>
        </router-link>

        <router-link to="/social-spaces/user-spaces">
          <div class="selection selection--three animated bounceInUp" @click="socialSpace">
            <div class="d-flex align-items-center gap-2">
              <img class="img-ico" src="/icons/c-ss.png" />
              <div class="text">My Social Spaces</div>
            </div>
          </div>
        </router-link>

        <router-link to="/my-creations?tab=folders">
          <div class="selection selection--four animated bounceInUp">
            <div class="d-flex align-items-center gap-2">
              <inline-svg width="42px" src="/icons/c-fo.svg" />
              <div class="text">My folders</div>
            </div>
          </div>
        </router-link>

        <router-link :to="`/profile/${user.username}/posts`">
          <div class="selection selection--five animated bounceInUp">
            <div class="d-flex align-items-center gap-2">
              <inline-svg width="38px" src="/icons/c-po.svg" />
              <div class="text">My Posts</div>
            </div>
          </div>
        </router-link>

        <router-link to="/marketplace/manage-listings">
          <div class="selection selection--six animated bounceInUp">
            <div class="d-flex align-items-center gap-2">
              <inline-svg width="37px" src="/icons/c-ml.svg" />
              <div class="text">My Marketplace Listings</div>
            </div>
          </div>
        </router-link>

        <router-link to="/presets">
          <div class="selection selection--seven animated bounceInUp">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-image" />
              <div class="text">My Presets</div>
            </div>
          </div>
        </router-link>

        <router-link to="/my-creations?tab=bookmarks">
          <div class="selection selection--eight animated bounceInUp">
            <div class="d-flex align-items-center gap-2">
              <i class="ti ti-bookmark" />
              <div class="text">My Bookmarks</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div v-if="isCreateOpen" class="create-overlay" @click="toggleCreate">
      <div>
        <router-link :to="{ name: 'character-creator' }">
          <div class="circle circle--one animated bounceInUp" @click="character">
            <div class="d-flex flex-column">
              <inline-svg width="33px" src="/icons/c-ch.svg" />
            </div>
          </div>
        </router-link>

        <router-link :to="{ name: 'create-story-world' }">
          <div class="circle circle--two animated bounceInUp" @click="world">
            <div class="d-flex flex-column">
              <inline-svg width="41px" src="/icons/c-st.svg" />
            </div>
          </div>
        </router-link>

        <router-link v-if="get(user, 'is_email_verified', true)" :to="{ name: 'create-social-space' }">
          <div class="circle circle--three animated bounceInUp" @click="socialSpace">
            <div class="d-flex flex-column">
              <img class="img-ico" src="/icons/c-ss.png" />
            </div>
          </div>
        </router-link>

        <div class="circle circle--three animated bounceInUp" v-else @click="openVerificationModal">
          <div class="d-flex flex-column">
            <img class="img-ico" src="/icons/c-ss.png" />
          </div>
        </div>

        <router-link :to="{ name: 'create-collection' }">
          <div class="circle circle--four animated bounceInUp">
            <div class="d-flex flex-column">
              <inline-svg width="42px" src="/icons/c-fo.svg" />
            </div>
          </div>
        </router-link>

        <router-link :to="{ name: 'create-post' }">
          <div class="circle circle--five animated bounceInUp">
            <div class="d-flex flex-column">
              <inline-svg width="38px" src="/icons/c-po.svg" />
            </div>
          </div>
        </router-link>

        <router-link :to="{ name: 'create-mp-listing' }">
          <div class="circle circle--six animated bounceInUp">
            <div class="d-flex flex-column">
              <inline-svg width="37px" src="/icons/c-ml.svg" />
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="not-signed-in" :class="{ img: joinImg }" v-if="notSignedInSection">
      <div v-if="joinImg">
        <a href="/signup"><img src="/join.png" /></a>
        <ion-button class="close-btn img-btn position-absolute" color="primary" @click="close">
          <i class="ti-close" />
        </ion-button>
      </div>
      <div class="box" v-else-if="notSignedInSection" :class="{ 'push-up': isMobSmallScreen }">
        <ion-button class="close-btn position-absolute" color="transparent" @click="close">
          <i class="ti-close" />
        </ion-button>
        <div class="title">
          {{ notSignedInSection?.title }}
        </div>
        <div class="subtitle">
          {{ notSignedInSection?.subtitle }}
        </div>
        <ion-button class="cta-btn" @click="goToSignup">
          {{ notSignedInSection?.cta || 'Join the Waitlist' }}
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { uiStore } from '@/shared/pinia-store/ui';
import { featureFlags } from '@/shared/config/feature-flags';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import { authStore } from '../pinia-store/auth';

const { isMobSmallScreen } = useWindowSize();
const { currentStep, stepForward, showOnboarding, showOverlay } = onboardingStore();

const joinImg = computed(() => featureFlags.joinImg);

const verificationModal = ref(false);

const { user } = authStore();

const {
  notSignedInSection,
  hideNotSignedInSection: close,
  isCreateOpen,
  toggleCreate,
  isMyCreationsOpen,
  toggleMyCreations,
} = uiStore();

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const goToSignup = () => {
  const router = useRouter();
  router.push({ name: 'signup' });
};

async function character() {
  const refreshOn = [
    'character-creator',
    'edit-char-draft',
    'character-editor',
    'draft-character-editor',
    'preset-creator',
    'preset-editor',
  ];
  const router = useRouter();
  const currentRouteName = router.currentRoute.value.name as string;
  if (refreshOn.includes(currentRouteName)) {
    const href = router.resolve({ name: 'character-creator' }).href;
    location.assign(href);
  }
}

async function world() {
  const router = useRouter();
  const refreshOn = ['create-story-world', 'draft-story-world', 'edit-world'];
  const currentRouteName = router.currentRoute.value.name as string;
  if (refreshOn.includes(currentRouteName)) {
    const href = router.resolve({ name: 'create-story-world' }).href;
    location.assign(href);
  }
}

async function socialSpace() {
  const router = useRouter();
  const refreshOn = ['create-social-space', 'edit-social-space'];
  const currentRouteName = router.currentRoute.value.name as string;
  if (refreshOn.includes(currentRouteName)) {
    const href = router.resolve({ name: 'create-social-space' }).href;
    location.assign(href);
  }
}
</script>

<style scoped lang="sass">
.my-creations-menu
  background: rgba(10, 9, 40, 0.8)
  border-radius: 10px
  padding: 10px
  position: absolute
  pointer-events: all
.o-msg
  position: absolute
  display: none
  color: #fff
  background: var(--ion-color-primary)
  border-radius: 50px
  padding: 2px 8px
  font-size: 14px
.img-ico
  width: 48px
  height: 48px
.o-disable
  cursor: default !important
@keyframes pointingarrow
  0%
    transform: translateX(0)
  50%
    transform: translateX(-1em)
  100%
    transform: translateX(0em)

.color-pink
  color: var(--ch-primary-color)
.onboarding
  position: inherit
  width: 100vw
  height: 100%
  pointer-events: none
  .o-arrow
    display: none
    position: absolute
    top: 0px
    left: 0px
    width: 120px
    height: 83px
    background: url('/onboarding/arrow_pointing.svg')
    -webkit-animation: pointingarrow 0.6s infinite
  .hole
    pointer-events: none
    position: absolute
    left: 0px
    top: 0px
    width: 0px
    height: 0px
    border-radius: 2000px
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7)
  .msg
    pointer-events: all
    width: 480px
    height: 150px
    position: absolute
    z-index: 2
    font-size: 14px
    color: var(--ch-black-color)
    font-weight: bold
    text-align: center
    background: rgba(251, 242, 201, 1)
    border-radius: var(--border-radius-sm)
    min-height: 40px
    align-items: center
    justify-content: center
    box-shadow: 0px 0px 8px 0px rgba(1, 1, 35, 0.75)
    left: 15px
    bottom: 70px
    cursor: pointer
    left: 0
    right: 0
    margin: auto
    &.top
      bottom: unset
      top: 80px
    &.push-up
      bottom: 230px !important
    @media(max-width: 500px)
      width: 314px
  .cta
    color: rgba(0, 180, 197, 1)
    font-style: italic
    font-size: 12px
    position: absolute
    bottom: 5px
    left: 0
    right: 0
    margin: auto
.sections
  width: 100vw
  height: var(--doc-height)
  pointer-events: none
  z-index: 5000
  position: absolute
  &.top
    z-index: 50000
  .not-signed-in
    position: absolute
    max-width: 350px
    z-index: 3
    pointer-events: all
    width: 350px
    bottom: 20px
    right: 24px
    &.img
      width: 190px
      right: 50px
      border-radius: 12px
      background: #FFF
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px 0px
    .box
      border-radius: 24px
      padding: 32px
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px 0px
      transition: all 0.3s ease-in-out 0s
      background: #FFF
      text-align: center
    &.push-up
      bottom: 86px !important
    .title
      font-size: 20px !important
      margin-bottom: 8px
      font-weight: 700 !important
    .subtitle
      font-size: 16px !important
      font-weight: 300 !important
      margin-bottom: 24px
    .cta-btn
      --border-radius: 20px
    .close-btn
      height: 32px
      width: 32px
      color: black
      --border-radius: 20px
      right: 5px
      top: 5px
      --padding-start: 8px
      --padding-end: 8px
    .img-btn
      --ion-color-primary: rgba(0,0,0,0.7)
    .terms
      margin-top: 12px
      font-size: 12px

@media(max-width: 520px)
  .sections
    .not-signed-in
      right: 20px
      width: calc(100% - 40px)
      max-width: unset
      &.img
        left: 20px
        width: 190px
        max-width: 300px !important


.create-overlay
  background: rgba(10, 9, 40, 0.8)
  width: 100vw
  height: var(--doc-height)
  text-align: center
  pointer-events: all
  display: flex
  align-items: center
  justify-content: center
.my-creations-overlay
  background: rgba(10, 9, 40, 0.8)
  width: 100vw
  height: var(--doc-height)
  text-align: center
  pointer-events: all
  display: flex
  align-items: center
  justify-content: center
  .flexer
    width: 250px
    display: flex
    flex-wrap: nowrap
    flex-direction: column
    align-items: center
    justify-content: center
.circle
  margin: 1.5em 1em
  width: 6rem
  height: 6rem
  background: red
  text-align: center
  line-height: 1rem
  font-weight: bold
  font-size: 13px
  color: #FFF
  display: inline-flex
  align-items: center
  justify-content: center
  border-radius: 60%
  -webkit-animation-iteration-count: 1
  -webkit-animation-fill-mode: backwards
  cursor: pointer
  -webkit-transform: translateY(2000px)
  &:hover
    opacity: 0.8
  *
    color: #FFF !important
  .ico
    font-size: 30px
    margin-bottom: 5px


.circle--one
  background: var(--ch-gradient-1)
  -webkit-animation-delay: 0s
  -webkit-animation-duration: 0.7s
  position: relative
  &::after
    content: "Character"
    display: block
    position: absolute
    top: 110%


.circle--two
  background: var(--ch-gradient-2)
  -webkit-animation-delay: 0.1s
  -webkit-animation-duration: 0.7s
  &::after
    content: "Story World"
    display: block
    position: absolute
    top: 110%


.circle--three
  background: var(--ch-gradient-3)
  -webkit-animation-delay: 0.02s
  -webkit-animation-duration: 0.7s
  &::after
    content: "Social Spaces"
    display: block
    position: absolute
    top: 110%


.circle--four
  background: var(--ch-gradient-4)
  -webkit-animation-delay: 0.03s
  -webkit-animation-duration: 0.7s
  &::after
    content: "Folder"
    display: block
    position: absolute
    top: 110%


.circle--five
  background: var(--ch-gradient-5)
  -webkit-animation-delay: 0.15s
  -webkit-animation-duration: 0.7s
  &::after
    content: "Post"
    display: block
    position: absolute
    top: 110%

.circle--six
  background: var(--ch-gradient-6)
  -webkit-animation-delay: 0.1s
  -webkit-animation-duration: 0.7s
  line-height: 0.8rem !important
  &::after
    content: "Marketplace Listing"
    display: block
    position: absolute
    top: 110%
  .ico
    margin-top: 5px


.selection
  margin: 0.5em 1em
  width: 200px
  height: 50px
  background: red
  text-align: center
  line-height: 1rem
  font-weight: bold
  font-size: 13px
  color: #FFF
  display: inline-flex
  align-items: center
  justify-content: center
  border-radius: 25px
  -webkit-animation-iteration-count: 1
  -webkit-animation-fill-mode: backwards
  cursor: pointer
  -webkit-transform: translateY(2000px)
  @media(max-height: 565px)
    height: 40px
    margin: 0.35em 1em
  @media(max-height: 478px)
    height: 35px
    margin: 0.25em 1em
  .ti
    font-size: 30px
  .text
    width: 120px
  &:hover
    opacity: 0.8
  *
    color: #FFF !important
  .ico
    font-size: 30px
    margin-bottom: 5px

.selection
  img, svg
    height: 30px
    width: 30px

.selection--one
  background: var(--ch-gradient-1)
  -webkit-animation-delay: 0s
  -webkit-animation-duration: 0.7s
  position: relative

.selection--two
  background: var(--ch-gradient-2)
  -webkit-animation-delay: 0.1s
  -webkit-animation-duration: 0.7s

.selection--three
  background: var(--ch-gradient-3)
  -webkit-animation-delay: 0.02s
  -webkit-animation-duration: 0.7s

.selection--four
  background: var(--ch-gradient-4)
  -webkit-animation-delay: 0.03s
  -webkit-animation-duration: 0.7s

.selection--five
  background: var(--ch-gradient-5)
  -webkit-animation-delay: 0.15s
  -webkit-animation-duration: 0.7s

.selection--six
  background: var(--ch-gradient-6)
  -webkit-animation-delay: 0.1s
  -webkit-animation-duration: 0.7s
  line-height: 0.8rem !important
  .ico
    margin-top: 5px

.selection--seven
  background: var(--ch-gradient-7)
  -webkit-animation-delay: 0.1s
  -webkit-animation-duration: 0.7s
  line-height: 0.8rem !important

.selection--eight
  background: var(--ch-gradient-8)
  -webkit-animation-delay: 0.1s
  -webkit-animation-duration: 0.7s
  line-height: 0.8rem !important

.selection--nine
  background: var(--ch-gradient-9)
  -webkit-animation-delay: 0.1s
  -webkit-animation-duration: 0.7s
  line-height: 0.8rem !important

.backdrop
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, 0.8)
  z-index: 1

@-webkit-keyframes bounceInUp
  0%
    opacity: 0
    -webkit-transform: translateY(2000px)


  60%
    opacity: 1
    -webkit-transform: translateY(-30px)


  80%
    -webkit-transform: translateY(10px)


  100%
    -webkit-transform: translateY(0)

@-moz-keyframes bounceInUp
  0%
    opacity: 0
    -moz-transform: translateY(2000px)


  60%
    opacity: 1
    -moz-transform: translateY(-30px)


  80%
    -moz-transform: translateY(10px)


  100%
    -moz-transform: translateY(0)

@-o-keyframes bounceInUp
  0%
    opacity: 0
    -o-transform: translateY(2000px)


  60%
    opacity: 1
    -o-transform: translateY(-30px)


  80%
    -o-transform: translateY(10px)


  100%
    -o-transform: translateY(0)

@keyframes bounceInUp
  0%
    opacity: 0
    transform: translateY(2000px)


  60%
    opacity: 1
    transform: translateY(-30px)


  80%
    transform: translateY(10px)


  100%
    transform: translateY(0)

.animated.bounceInUp
  -webkit-animation-name: bounceInUp
  -moz-animation-name: bounceInUp
  -o-animation-name: bounceInUp
  animation-name: bounceInUp
  -webkit-animation-fill-mode: forwards


@keyframes float
  0%
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6)
    transform: translatey(0px)

  50%
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2)
    transform: translatey(-20px)

  100%
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6)
    transform: translatey(0px)
</style>
