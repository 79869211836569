import { defineStore, storeToRefs } from 'pinia';
import { nanoid } from 'nanoid';
import { toast } from '@/shared/native/toast';
import { newAboutKey, newTextKey } from './shared/character';
import {
  createSaveDraft,
  editCharacterSaveDraft,
  editCharacterSaveDraftAuto,
  editCharacterAuto,
  getCharacterDraft,
  getCharacterByIdFull,
  postCharacterTriviaQuestions,
} from '@/shared/actions/characters';
import {
  CharacterRelationship,
  CharacterRelationshipStatus,
  PaginatedResponse,
  Character,
  Image,
  ImageCollection,
  KeyedColor,
} from '@/shared/types/static-types';

import { unreorderableEditorKeys } from '@/shared/statics/constants';
import {
  deleteCharacterRelationship,
  acceptCharacterRelationshipRequest,
  getPrivateCharacterRelationships,
  queryCharactersForRelationships,
  reorderRelationships,
} from '@/shared/actions/characterRelationships';
import {
  reorderImageCollectionImages,
  handleObjectImageUpdates,
  removeImages,
} from '@/shared/actions/imagesCollections';
import {
  formatStatsIn,
  formatStatsOut,
  formatCharacterQualitiesIn,
  formatCharacterQualitiesOut,
} from '@/shared/utils/transform';
import { imageStore } from '@/shared/pinia-store/images';
import logger from '@/shared/services/logger';
import { generateRandomID } from '../utils/string';

export interface ICharacterEditorState {
  currentCharacter: Character | null;
  currentImageCollections: ImageCollection[];
  currentImageCollectionsChanged: boolean;
  template: any;
  isLoading: boolean;
  isDraft: boolean;
  isCharacter: boolean;
  isDropDownCollapsed: boolean;
  allRelationships: CharacterRelationship[];
  innerRelationships: CharacterRelationship[];
  mutatedInnerRelationships: CharacterRelationship[];
  outerRelationships: CharacterRelationship[];
  mutatedOuterRelationships: CharacterRelationship[];
  lookupResultsValue: PaginatedResponse<Character[]> | null;
  isDone: boolean;
  keyedColors?: KeyedColor[];
  didUserCreateCharacter: boolean;
  isDirty: boolean;
  isSaving: boolean;
  autosaveEnabled: boolean;
  images: any[];
  removedCharImages: { ids: string[]; urls: string[] };
  questions: any[];
  deletedQuestions: any[];
}

export const useCharacterEditor = defineStore('character-editor', {
  state: (): ICharacterEditorState => ({
    currentCharacter: null,
    currentImageCollections: [],
    currentImageCollectionsChanged: false,
    isLoading: false,
    template: null,
    isDraft: true,
    isCharacter: true,
    allRelationships: [],
    innerRelationships: [],
    mutatedInnerRelationships: [],
    outerRelationships: [],
    mutatedOuterRelationships: [],
    lookupResultsValue: null,
    isDone: false,
    keyedColors: [],
    didUserCreateCharacter: true,
    isDirty: false,
    isSaving: false,
    isDropDownCollapsed: true,
    autosaveEnabled: false,
    images: [],
    removedCharImages: { ids: [], urls: [] },
    questions: [],
    deletedQuestions: [],
  }),
  actions: {
    markAsDirty() {
      this.isDirty = true;
    },
    setIsDone(val: boolean) {
      this.isDone = val;
    },
    setAutosaveEnabled(val: boolean) {
      this.autosaveEnabled = val;
    },
    async startLookup(query: string, route: string, page = 1) {
      const characterIdToSend =
        !this.character?.id || route === 'draft-character-editor' || this.character?.privacy !== 'P'
          ? ''
          : this.character?.id;
      const res = await queryCharactersForRelationships(query, page, characterIdToSend);
      this.lookupResultsValue = {
        ...res,
        results: res.results.filter((character: Character) => character.id !== this.character?.id),
        page,
      };
    },
    async nextPageLookup(query: string, route: string) {
      const characterIdToSend =
        !this.character?.id || route === 'draft-character-editor' || this.character?.privacy !== 'P'
          ? ''
          : this.character?.id;
      const res = await queryCharactersForRelationships(
        query,
        (this.lookupResultsValue?.page || 0) + 1,
        characterIdToSend
      );
      this.lookupResultsValue = {
        ...res,
        results: [
          ...(this.lookupResultsValue?.results || []),
          ...res.results.filter((character: Character) => character.id !== this.character?.id),
        ],
        page: (this.lookupResultsValue?.page || 0) + 1,
      };
    },
    clearLookup() {
      this.lookupResultsValue = null;
    },
    setMutatedInnerRelationship(index: number, value: CharacterRelationship) {
      const tempId = this.mutatedInnerRelationships[index]._id;
      value._id = tempId || value.id;
      this.mutatedInnerRelationships[index] = value;
      this.isDirty = true;
    },
    async deleteOuterRelationship(id: string) {
      try {
        await deleteCharacterRelationship(id);
        this.mutatedOuterRelationships = this.mutatedOuterRelationships.filter(
          (relationship) => relationship.id !== id
        );
        this.mutatedInnerRelationships = this.mutatedInnerRelationships.filter(
          (relationship) => relationship.id !== id
        );
        this.isDirty = true;
      } catch (error) {
        toast.show('Error deleting character relation', 'nonative', 'danger');
      }
    },
    async deleteInnerRelationship(id: string) {
      try {
        await deleteCharacterRelationship(id);
        this.mutatedInnerRelationships = this.mutatedInnerRelationships.filter(
          (relationship) => relationship.id !== id
        );
        this.mutatedOuterRelationships = this.mutatedOuterRelationships.filter(
          (relationship) => relationship.id !== id
        );
        this.isDirty = true;
      } catch (error) {
        toast.show('Error deleting character relation', 'nonative', 'danger');
      }
    },
    async acceptRelationshipRequest(id: string) {
      try {
        await acceptCharacterRelationshipRequest(id);
        this.mutatedOuterRelationships = this.mutatedOuterRelationships.map((relationship) => {
          if (relationship.id === id) {
            relationship.state = CharacterRelationshipStatus.RequestAccepted;
          }
          return relationship;
        });
        this.mutatedInnerRelationships.push(
          this.mutatedOuterRelationships.find((relationship) => relationship.id === id)!
        );
        toast.show('Accepted character relation', 'nonative', 'primary');
        this.isDirty = true;
      } catch (error) {
        toast.show('Error accepting character relation', 'nonative', 'danger');
      }
    },
    setIsDraft(isDraft: boolean) {
      this.isDraft = isDraft;
    },
    async getCharacterRelationships() {
      try {
        const characterID = this.currentCharacter?.id;
        if (!characterID) return;

        const { results } = await getPrivateCharacterRelationships(characterID);
        this.innerRelationships = results.filter(
          (relationship: CharacterRelationship) =>
            relationship.character1?.id === this.character?.id ||
            relationship.state === CharacterRelationshipStatus.RequestAccepted
        );
        this.outerRelationships = results.filter(
          (relationship: CharacterRelationship) => relationship.character2?.id === this.character?.id
        );
        this.syncRelationships();
      } catch (error) {}
    },
    syncRelationships() {
      this.innerRelationships.forEach((relationship) => {
        relationship._id = relationship.id;
      });
      // sort innerRelationships by 'order'
      this.outerRelationships.forEach((relationship) => {
        relationship._id = relationship.id;
      });
      // set _id field for each relationship
      this.mutatedInnerRelationships = cloneDeep(this.innerRelationships);
      this.mutatedOuterRelationships = cloneDeep(this.outerRelationships);
    },
    async setNewRelationshipOrdering(newOrder: string[]) {
      await reorderRelationships(this.currentCharacter!.id!, newOrder);
      this.isDirty = true;
    },
    async initNewCharacter(route: string, presetId?: string) {
      const charData = presetId ? await getCharacterDraft(presetId) : {};
      const pickedProps = pick(charData, [
        'info',
        'character_collections',
        'visual_collections_page',
        'is_nsfw',
        'is_public',
        'allow_roleplay_req',
        'allow_comp_bomb',
        'original_creator',
      ]);
      if (!Object.prototype.hasOwnProperty.call(pickedProps, 'allow_comp_bomb')) {
        pickedProps.allow_comp_bomb = true;
      }
      if (!Object.prototype.hasOwnProperty.call(pickedProps, 'allow_roleplay_req')) {
        pickedProps.allow_roleplay_req = true;
      }
      const usedCharData = {
        ...pickedProps,
        info: {
          name: null,
          description: '',
          about: {
            pronouns: '',
            age: '',
            species: '',
          },
          aboutConfig: {
            pronouns: { orderNum: 1, id: nanoid(8) },
            age: { orderNum: 2, id: nanoid(8) },
            species: { orderNum: 3, id: nanoid(8) },
          },
          privacy: 'P',
          collections: 0,
          ...pickedProps.info,
        },
      };

      const order: string[] = usedCharData?.info?.order || Object.keys(usedCharData?.info);

      usedCharData.info.order = order.filter((key: string) => !unreorderableEditorKeys.includes(key));

      this.currentCharacter = {
        ...usedCharData,
      } as Character;

      if (route === 'preset-editor' || route === 'preset-creator') {
        this.template = {};
      } else {
        this.template = null;
      }
      this.isDirty = false;
      this.isSaving = false;
      this.autosaveEnabled = false;
    },
    setCharacterTemplate(template: any) {
      this.template = template;
    },
    async loadEditor(id: string, route: string, presetId?: string) {
      this.clearAll();
      this.isLoading = true;
      let errorEncountered = false;
      try {
        if (!id) {
          await this.initNewCharacter(route, presetId);
          this.isLoading = false;
          this.isDirty = false;
          this.isSaving = false;
          this.autosaveEnabled = true;
          return;
        }

        let characterInstance: Character;

        if (route === 'character-editor') {
          this.isDraft = false;
        }
        if (route === 'draft-character-editor' || route === 'preset-editor') {
          if (route === 'preset-editor') {
            this.isDraft = false;
            this.template = {};
          } else {
            this.isDraft = true;
          }
          characterInstance = await getCharacterDraft(id);
        } else if (presetId) {
          this.isDraft = false;
          this.isCharacter = false;
          characterInstance = await getCharacterDraft(id);
          characterInstance.info.profilePicture = characterInstance.info.cropProfilePicture = '';
        } else {
          this.isCharacter = true;
          characterInstance = await getCharacterByIdFull(id);
        }
        this.currentCharacter = characterInstance;
        this.currentCharacter.allow_roleplay_req = this.currentCharacter.allow_roleplay_req || false;
        this.currentCharacter.allow_comp_bomb = this.currentCharacter.allow_comp_bomb || false;

        if (route === 'draft-character-editor' || route === 'character-creator') {
          if (!this.currentCharacter) {
            this.currentCharacter = {} as Character;
          }
          if (!this.currentCharacter.info.privacy) this.currentCharacter.info.privacy = 'P';
        }
        await this.afterCharacterLoad();
      } catch (error) {
        logger.error({
          e: error,
          loc: 'character-editor.loadEditor',
          msg: 'character-editor loadEditor: ' + error,
          data: {
            currentCharacter: this.currentCharacter,
            route,
            id,
            presetId,
            isDraft: this.isDraft,
          },
        });
        toast.show('Error loading character', 'nonative', 'danger');
        errorEncountered = true;
      } finally {
        this.isLoading = false;
        this.isDirty = false;
        this.isSaving = false;
      }
      if (errorEncountered) this.autosaveEnabled = false;
      else if (this.isDraft && this.currentCharacter?.id) this.autosaveEnabled = true;
      else if (route === 'preset-editor') this.autosaveEnabled = true;
      else if (this.currentCharacter?.privacy !== 'P') this.autosaveEnabled = true;
      else this.autosaveEnabled = false;
    },
    async afterCharacterLoad() {
      if (this.currentCharacter) {
        const order = this.currentCharacter?.info.order || [];

        this.currentCharacter.info.order = order.map((i: string) => {
          if (i === '[object Object]') return generateRandomID();

          return i;
        });
      }

      const { setImageCollections } = imageStore();
      if (!this.currentCharacter?.info) return;
      if (this.currentCharacter.visual_collections_page) {
        this.currentImageCollections = this.currentCharacter.visual_collections_page.results as ImageCollection[];
      }
      if (!this.currentCharacter.info.character_collections && this.currentCharacter?.character_collections?.length) {
        this.currentCharacter.info.characterCollections = {};
      }

      if (this.currentCharacter.original_creator) this.didUserCreateCharacter = false;
      else this.didUserCreateCharacter = true;

      if (this.currentCharacter.info.colors) {
        this.keyedColors = this.currentCharacter.info.colors.map((color) => {
          return { id: nanoid(6), hex_code: color.hex_code, label: color.label };
        });
      }

      if (this.currentCharacter.info.order) {
        // clean up characters made on editor v1 which might have invalid values for "order"
        this.currentCharacter.info.order = this.currentCharacter.info.order.filter(
          (key: string) => !unreorderableEditorKeys.includes(key)
        );
      } else {
        // or if there's no data for "order" at all, generate it
        this.currentCharacter.info.order = Object.keys(this.currentCharacter.info).filter(
          (key: string) => !unreorderableEditorKeys.includes(key)
        );
        // hack: move description to the top if generating order for the user
        const index = this.currentCharacter.info.order.indexOf('description');
        if (index > -1) this.currentCharacter.info.order.unshift(this.currentCharacter.info.order.splice(index, 1)[0]);
      }
      setImageCollections(this.currentImageCollections);
      await this.getCharacterRelationships();
      this.isSaving = false;
      setTimeout(() => {
        // give some time to let the editor components update
        this.isDirty = false;
      }, 1000);
    },
    changeProfilePicture(url: string) {
      if (!this.currentCharacter) return;
      this.currentCharacter.info.profilePicture = url;
      this.currentCharacter.info.cropProfilePicture = url;
      this.isDirty = true;
    },
    changeCover(url: string) {
      if (!this.currentCharacter) return;
      this.currentCharacter.info.cover = url;
      this.isDirty = true;
    },
    clear() {
      const { reset: resetImageStore } = imageStore();
      this.currentCharacter = null;
      this.currentImageCollections = [];
      this.currentImageCollectionsChanged = false;
      this.isDirty = false;
      this.isSaving = false;
      this.autosaveEnabled = false;
      this.keyedColors = [];
      resetImageStore();
    },
    clearAll() {
      const { reset: resetImageStore } = imageStore();
      this.currentCharacter = null;
      this.currentImageCollections = [];
      this.currentImageCollectionsChanged = false;
      this.isLoading = false;
      this.template = null;
      this.isDraft = true;
      this.isCharacter = true;
      this.keyedColors = [];
      this.isDirty = false;
      this.isSaving = false;
      this.autosaveEnabled = false;
      resetImageStore();
    },
    changeLocalImageCollections(collections: any[]) {
      if (!this.currentCharacter) return;
      this.currentCharacter.visual_collections = collections;
      this.currentImageCollections = collections;
      this.currentImageCollectionsChanged = true;
      this.currentCharacter.info.collections = collections.length;
      this.isDirty = true;
    },
    changeLocalImageCollectionImageOrder(collectionId: string, images: Image[]) {
      if (!this.currentCharacter) return;
      const collection = this.currentImageCollections.find((c: any) => c.id === collectionId);
      if (!collection) return;
      collection.images_page!.results = images;
      reorderImageCollectionImages(collectionId, images.map((i: Image) => i.id).filter(Boolean) as string[]);
      this.isDirty = true;
    },
    changeInfoKey(key: string, value: any) {
      if (!this.currentCharacter) return;
      this.currentCharacter.info[key] = value;
      this.isDirty = true;
    },
    changeKey(key: string, value: any) {
      if (!this.currentCharacter) return;
      this.currentCharacter[key] = value;
      this.isDirty = true;
    },
    collapseDropDown(value: any) {
      this.isDropDownCollapsed = value;
    },
    removeSection(key: string) {
      if (!this.currentCharacter) return;

      this.currentCharacter.info = omit(
        {
          ...this.currentCharacter?.info,
        },
        key
      ) as Character['info'];
      if (this.currentCharacter?.info?.order?.length) {
        this.currentCharacter.info.order = this.currentCharacter.info.order.filter((k: string) => k !== key);
      }
      this.isDirty = true;
    },
    addSection(key: string) {
      const arrKeys = [
        'moodboard',
        'featuredIn',
        'colors',
        'relationshipsSection',
        'timeline',
        'outfits',
        'favorites',
        'inventory',
        'life_stages',
        'trivia',
        'alignment_charts',
      ];
      const objKeys = ['characterCollections', 'about', 'stats', 'qualities', 'moreAbout'];
      const numKeys = ['collections'];
      const normalTextKeys = ['backstory', 'creatorsNote', 'description', 'quote', 'spotifyLink', 'warningMessage'];
      if (!this.currentCharacter) return;
      let _newTextKey = '';
      let _newAboutKey = '';

      if (key === 'text') {
        _newTextKey = newTextKey(this.currentCharacter);
        this.currentCharacter.info[_newTextKey] = {
          type: 'text',
          body: '',
          title: '',
        };
      }

      if (key === 'moreAbout') {
        _newAboutKey = newAboutKey(this.currentCharacter);
        const newAboutConfigKey = `${_newAboutKey}Config`;
        this.currentCharacter.info[_newAboutKey] = {
          type: 'about',
          body: {
            pronouns: '',
            age: '',
            species: '',
          },
          title: 'More About',
        };
        this.currentCharacter.info[newAboutConfigKey] = cloneDeep(this.currentCharacter.info.aboutConfig);
      }

      if (objKeys.includes(key)) {
        this.currentCharacter.info[key] = {};
      }

      if (arrKeys.includes(key)) {
        this.currentCharacter.info[key] = [];
      }

      if (normalTextKeys.includes(key)) {
        this.currentCharacter.info[key] = '';
      }

      if (numKeys.includes(key)) {
        this.currentCharacter.info[key] = 0;
      }

      const usedKey = key === 'text' ? _newTextKey : key === 'moreAbout' ? _newAboutKey : key;
      this.currentCharacter.info.order = [...(this.currentCharacter.info.order || []), usedKey];
      this.isDirty = true;
    },
    addInnerRelationship() {
      this.mutatedInnerRelationships.push({
        character1: null,
        character2: null,
        character1_description: '',
        character2_description: '',
        character1_status: '',
        character2_status: '',
        request_message: '',
        _id: nanoid(6),
      });
      this.isDirty = true;
    },
    addTimelineItem(timeline: any) {
      if (!this.currentCharacter) return;
      this.currentCharacter.info.timeline = timeline;
      this.isDirty = true;
    },

    addTriviaDeletedItems(question: any) {
      if (!this.currentCharacter) return;
      this.deletedQuestions.push(question);
    },
    addTriviaItems(allQuestion: any) {
      if (!this.currentCharacter) return;
      this.questions = allQuestion;
      this.currentCharacter.info.trivia = this.questions.map((ques) => ques?.id);
      this.currentCharacter.info.triviaConfig = this.questions.map((ques) => ques?.extra.ques_id);
      this.isDirty = true;
    },
    addAlignmentChart(value: any) {
      if (!this.currentCharacter) return;
      this.currentCharacter.info.alignment_charts = value;
      this.isDirty = true;
    },
    addAlignmentChartCustomizeText(index: any, value: any, title: any) {
      if (!this.currentCharacter) return;
      this.currentCharacter.info.alignment_charts[index][value] = title;
      this.isDirty = true;
    },
    async commitOutfitsChanges(parent: { id?: string; model?: string }) {
      const payload = {
        images: { update: keyBy(this.images, 'id') },
        id: parent.id,
        object_type: parent.model,
      } as any;

      await handleObjectImageUpdates(payload);
      this.images = [];

      await this.commitRemovedCharImages();
    },
    async commitTriviaChanges(parent: { id?: string; model?: string }) {
      const payload = {
        create: this.questions.filter((item: any) => item.id === ''),
        update: this.questions.filter((item: any) => item.id !== ''),
        delete: this.deletedQuestions,
        entity_id: parent.id,
        entity_type: parent.model,
      } as any;

      const res = await postCharacterTriviaQuestions(payload);
      this.questions = [];
      this.questions = res;

      if (!this.currentCharacter) return;

      this.currentCharacter.info.trivia = this.questions.map((ques) => ques?.id);
    },
    addOutfits(outfits: any, isReorder: boolean, key: string) {
      if (!this.currentCharacter) return;
      if (isReorder) {
        this.currentCharacter.info[key] = [];
        this.currentCharacter.info[key] = outfits;
      } else {
        this.currentCharacter.info[key] = this.currentCharacter.info[key].concat(outfits);
      }
      if (key === 'outfits') {
        this.currentCharacter.info.outfit_ids = this.currentCharacter.info[key].map((outfit: any) => outfit.id);
      } else if (key === 'favorites') {
        this.currentCharacter.info.favorites_ids = this.currentCharacter.info[key].map((outfit: any) => outfit.id);
      } else if (key === 'inventory') {
        this.currentCharacter.info.inventory_ids = this.currentCharacter.info[key].map((outfit: any) => outfit.id);
      } else {
        this.currentCharacter.info.life_stages_ids = this.currentCharacter.info[key].map((outfit: any) => outfit.id);
      }
      this.isDirty = true;
    },
    editImages(outfits: any, key: string) {
      if (!this.currentCharacter) return;
      this.images = this.images.concat(outfits);

      outfits.forEach((img: any) => {
        if (!this.currentCharacter) return;

        this.currentCharacter.info[key] = this.currentCharacter?.info[key].map((image: any) => {
          if (image.id === img.id) {
            return img;
          } else {
            return image;
          }
        });
      });

      this.isDirty = true;
    },
    setRemovedCharImages({
      ids = [],
      urls = [],
      replace = false,
    }: {
      ids?: string[];
      urls?: string[];
      replace?: boolean;
    }) {
      this.removedCharImages = replace
        ? { ids, urls }
        : { ids: this.removedCharImages.ids.concat(ids), urls: this.removedCharImages.urls.concat(urls) };
    },
    async commitRemovedCharImages() {
      if (this.removedCharImages.ids.length || this.removedCharImages.urls.length) {
        await removeImages(this.removedCharImages);
      }
      this.removedCharImages = { ids: [], urls: [] };
    },
    setCharacterColorsEditable(colorObjs: KeyedColor[]) {
      // { color: '#000000', id: 'fjqpek' }
      if (!this.currentCharacter) return;
      this.keyedColors = colorObjs;
      const toStore = colorObjs.map((x) => {
        return {
          hex_code: x.hex_code,
          label: x.label || '',
        };
      });

      this.currentCharacter.info.colors = toStore;
      this.isDirty = true;
    },
    setCharacterAbout() {
      this.isDirty = true;
    },
    setCharacterQualities(characterRows: any[]) {
      if (!this.currentCharacter) return;
      const { qualities, config } = formatCharacterQualitiesOut(characterRows);
      this.currentCharacter.info.qualities = qualities;
      this.currentCharacter.info.qualitiesConfig = config;
      this.isDirty = true;
    },
    setCharacterStats(statsRows: any[]) {
      if (!this.currentCharacter) return;
      const { stats, config } = formatStatsOut(statsRows);
      this.currentCharacter.info.stats = stats;
      this.currentCharacter.info.statsConfig = config;
      this.isDirty = true;
    },
    async saveAsDraft() {
      const { commitAllChanges } = imageStore();
      this.isSaving = true;
      try {
        let response;
        let visualCollectionIds = this.currentImageCollectionsChanged
          ? this.currentImageCollections?.map((x) => x.id as string)
          : null;
        const isNew = !this.character?.id;
        if (isNew) {
          visualCollectionIds = await commitAllChanges({});
          this.commitOutfitsChanges({});
          await this.commitTriviaChanges({});
          response = await createSaveDraft(
            this.character?.info,
            this.character?.is_nsfw,
            visualCollectionIds,
            this.character?.character_collections,
            this.character?.info?.tags,
            'draft',
            {
              allow_roleplay_req: this.character?.allow_roleplay_req || false,
              allow_comp_bomb: this.character?.allow_comp_bomb || false,
              original_creator: this.didUserCreateCharacter ? '' : this.character?.original_creator,
            }
          );
          await editCharacterSaveDraft(response.id, this.character?.is_nsfw, this.character?.info, visualCollectionIds);
        } else {
          visualCollectionIds = await commitAllChanges({ id: this.character.id!, model: 'character_draft' });
          this.commitOutfitsChanges({ id: this.character.id!, model: 'character_draft' });
          await this.commitTriviaChanges({ id: this.character.id!, model: 'characterdraft' });
          response = await editCharacterSaveDraft(
            this.character?.id,
            this.character?.is_nsfw,
            this.character?.info,
            visualCollectionIds,
            this.character?.character_collections,
            this.character?.info?.tags,
            'draft',
            {
              allow_roleplay_req: this.character?.allow_roleplay_req || false,
              allow_comp_bomb: this.character?.allow_comp_bomb || false,
              original_creator: this.didUserCreateCharacter ? '' : this.character?.original_creator,
              is_fanchar: this.character?.is_fanchar,
              is_fanchar_spinoff: this.character?.is_fanchar_spinoff,
            }
          );
        }
        this.character!.id = response.id;
        this.character!.info = response.info;
        this.isDone = true;
        const router = useRouter();
        router.push({ name: 'draft-character-editor', params: { id: this.character!.id } });
        toast.show('Character saved as draft', 'nonative', 'primary');
        this.isDirty = false;
      } catch (error) {
        toast.show('Failed to save character', 'nonative', 'danger');
      } finally {
        this.isSaving = false;
      }
    },
    async autosaveDraftNew() {
      if (!this.currentCharacter) return;
      if (this.isSaving) return;
      const isNew = !this.currentCharacter.id;
      if (!isNew) return;
      let visualCollectionIds = this.currentImageCollectionsChanged
        ? this.currentImageCollections?.map((x) => x.id as string)
        : null;
      const { commitAllChanges } = imageStore();
      try {
        this.isSaving = true;
        if (!this.currentCharacter.info.name) this.currentCharacter.info.name = 'Untitled';
        visualCollectionIds = await commitAllChanges({});
        this.commitOutfitsChanges({});
        await this.commitTriviaChanges({});
        const response = await createSaveDraft(
          this.currentCharacter.info,
          this.currentCharacter.is_nsfw,
          visualCollectionIds,
          this.currentCharacter.character_collections,
          this.currentCharacter.info?.tags,
          'draft',
          {
            allow_roleplay_req: this.character?.allow_roleplay_req || false,
            allow_comp_bomb: this.character?.allow_comp_bomb || false,
            original_creator: this.didUserCreateCharacter ? '' : this.currentCharacter.value?.original_creator,
          }
        );
        this.currentCharacter = {
          ...response,
          ...this.currentCharacter,
        };
        this.isDirty = false;
        this.isDone = true;
        history.replaceState({}, '', '/editor/character/draft/' + response.id);
      } catch (e: any) {
        toast.show('Autosave failed to create new draft.', 'nonative', 'danger');
      } finally {
        this.isSaving = false;
      }
    },
    async autosaveDraft() {
      const { commitAllChanges, unsavedChanges } = imageStore();
      if (this.isSaving) return;
      if (!this.autosaveEnabled || !this.currentCharacter) return;
      if (!this.currentCharacter.id) {
        let idNew = null;
        try {
          idNew = await this.autosaveDraftNew();
        } catch (e) {
          this.autosaveEnabled = false;
        } finally {
          return idNew;
        }
      }
      this.isSaving = true;
      try {
        let visualCollectionIds = this.currentImageCollectionsChanged
          ? this.currentImageCollections?.map((x) => x.id as string)
          : null;
        if (unsavedChanges.value) {
          visualCollectionIds = await commitAllChanges({ id: this.currentCharacter.id!, model: 'character_draft' });
        }
        this.commitOutfitsChanges({ id: this.currentCharacter.id!, model: 'character_draft' });
        await this.commitTriviaChanges({ id: this.currentCharacter.id!, model: 'characterdraft' });
        const response = await editCharacterSaveDraftAuto(
          this.currentCharacter!.id!,
          this.currentCharacter!.is_nsfw,
          this.currentCharacter!.info,
          visualCollectionIds,
          this.currentCharacter!.character_collections,
          this.currentCharacter!.info?.tags,
          'draft',
          {
            allow_roleplay_req: this.currentCharacter!.allow_roleplay_req,
            allow_comp_bomb: this.currentCharacter!.allow_comp_bomb,
            original_creator: this.didUserCreateCharacter ? '' : this.currentCharacter!.original_creator,
            modified: this.currentCharacter!.modified,
            is_fanchar: this.currentCharacter!.is_fanchar,
            is_fanchar_spinoff: this.currentCharacter!.is_fanchar_spinoff,
          }
        );
        this.currentCharacter.modified = response.modified;
        this.currentCharacter.id = response.id;
        this.isDirty = false;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This character has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.autosaveEnabled = false;
        } else {
          logger.error({
            e,
            loc: 'character-editor.autosaveDraft',
            msg: 'Character AutosaveDraft: ' + e,
            data: {
              currentCharacter: this.currentCharacter,
            },
          });
          toast.show('Autosave failed, trying again...', 'nonative', 'danger');
        }
      } finally {
        this.isSaving = false;
      }
    },
    async autosavePresetNew() {
      if (this.isSaving) return;
      if (!this.currentCharacter) return;
      let visualCollectionIds = this.currentImageCollectionsChanged
        ? this.currentImageCollections?.map((x) => x.id as string)
        : null;
      const isNew = !this.currentCharacter.id;
      this.isSaving = true;
      const { commitAllChanges } = imageStore();
      try {
        if (isNew) {
          if (!this.currentCharacter.info.name) this.currentCharacter.info.name = 'New Preset';
          visualCollectionIds = await commitAllChanges({});
          this.commitOutfitsChanges({});
          await this.commitTriviaChanges({});
          const response = await createSaveDraft(
            this.currentCharacter.info,
            this.currentCharacter.is_nsfw,
            visualCollectionIds,
            this.currentCharacter.character_collections,
            this.currentCharacter.info?.tags,
            'template',
            {
              template_title: this.currentCharacter.template_title,
              template_desc: this.currentCharacter.template_desc,
              allow_roleplay_req: this.currentCharacter.allow_roleplay_req,
              allow_comp_bomb: this.currentCharacter.allow_comp_bomb,
              original_creator: this.didUserCreateCharacter ? '' : this.currentCharacter.original_creator,
            }
          );
          this.currentCharacter = {
            ...response,
            ...this.currentCharacter,
          };
          this.isDirty = false;
          this.isDone = true;
        }
      } catch (e: any) {
        toast.show('Autosave failed to create new preset.', 'nonative', 'danger');
      } finally {
        this.isSaving = false;
      }
    },
    async autosavePreset() {
      const { commitAllChanges, unsavedChanges } = imageStore();
      if (!this.autosaveEnabled || !this.currentCharacter) return;
      if (this.isSaving) return;
      this.isSaving = true;

      if (!this.currentCharacter.id) {
        let idNew = null;
        try {
          idNew = await this.autosavePresetNew();
        } catch (e) {
          logger.error({
            e,
            loc: 'character-editor.autosavePresetNew',
            msg: 'Character AutosavePreset: ' + e,
            data: {
              currentCharacter: this.currentCharacter,
            },
          });
          toast.show('Autosave failed to create new preset.', 'nonative', 'danger');
          this.autosaveEnabled = false;
        } finally {
          this.isSaving = false;
          return idNew;
        }
      }

      try {
        let visualCollectionIds = this.currentImageCollectionsChanged
          ? this.currentImageCollections?.map((x) => x.id as string)
          : null;
        if (unsavedChanges.value) {
          visualCollectionIds = await commitAllChanges({ id: this.currentCharacter.id!, model: 'character_draft' });
        }
        this.commitOutfitsChanges({ id: this.currentCharacter.id!, model: 'character_draft' });
        await this.commitTriviaChanges({ id: this.currentCharacter.id!, model: 'characterdraft' });

        const response = await editCharacterSaveDraftAuto(
          this.currentCharacter.id,
          this.currentCharacter.is_nsfw,
          this.currentCharacter.info,
          visualCollectionIds,
          this.currentCharacter.character_collections,
          this.currentCharacter.info?.tags,
          'template',
          {
            modified: this.currentCharacter.modified,
            template_title: this.currentCharacter.template_title,
            template_desc: this.currentCharacter.template_desc,
            allow_roleplay_req: this.currentCharacter.allow_roleplay_req,
            allow_comp_bomb: this.currentCharacter.allow_comp_bomb,
            original_creator: this.didUserCreateCharacter ? '' : this.currentCharacter.original_creator,
            is_fanchar: this.currentCharacter!.is_fanchar,
            is_fanchar_spinoff: this.currentCharacter!.is_fanchar_spinoff,
          }
        );
        this.currentCharacter.modified = response.modified;
        this.currentCharacter.id = response.id;
        this.isDirty = false;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This character preset has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.autosaveEnabled = false;
        } else {
          logger.error({
            e,
            loc: 'character-editor.autosavePreset',
            msg: 'Character AutosavePreset: ' + e,
            data: {
              currentCharacter: this.currentCharacter,
            },
          });
          toast.show('Autosave failed, trying again...', 'nonative', 'danger');
        }
      } finally {
        this.isSaving = false;
      }
    },
    async autosaveCharacter() {
      const { commitAllChanges, unsavedChanges } = imageStore();
      if (this.isSaving) return;
      if (!this.currentCharacter?.id || !this.autosaveEnabled) return;
      this.isSaving = true;
      try {
        let visualCollectionIds = this.currentImageCollectionsChanged
          ? this.currentImageCollections?.map((x) => x.id as string)
          : null;
        if (unsavedChanges.value) {
          visualCollectionIds = await commitAllChanges({ id: this.currentCharacter.id!, model: 'character_draft' });
        }
        this.commitOutfitsChanges({ id: this.currentCharacter.id!, model: 'character_draft' });
        await this.commitTriviaChanges({ id: this.currentCharacter.id!, model: 'character' });
        const response = await editCharacterAuto(
          this.currentCharacter!.id!,
          this.currentCharacter!.info,
          this.currentCharacter!.allow_roleplay_req,
          this.currentCharacter!.allow_comp_bomb,
          this.currentCharacter!.is_nsfw,
          this.currentCharacter!.character_collections,
          visualCollectionIds!,
          this.currentCharacter!.info?.tags,
          this.didUserCreateCharacter ? '' : this.currentCharacter!.original_creator,
          {
            modified: this.currentCharacter!.modified,
            is_fanchar: this.currentCharacter!.is_fanchar,
            is_fanchar_spinoff: this.currentCharacter!.is_fanchar_spinoff,
          }
        );
        this.currentCharacter.modified = response.modified;
        this.currentCharacter.id = response.id;
        this.isDirty = false;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data.err_code === 'cannot_update') {
          this.autosaveEnabled = false;
          toast.show('You cannot publish NSFW content.', 'nonative', 'danger');
          return;
        } else if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This character has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.autosaveEnabled = false;
        } else {
          logger.error({
            e,
            loc: 'character-editor.autosaveCharacter',
            msg: 'AutosaveCharacter: ' + e,
            data: {
              currentCharacter: this.currentCharacter,
            },
          });
          toast.show('Autosave failed, trying again...', 'nonative', 'danger');
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
  getters: {
    character(): Character | null {
      return this.currentCharacter;
    },
    loading(): boolean {
      return this.isLoading;
    },
    isTemplate(): boolean {
      return !!this.template;
    },
    innerRelationshipsList(): any[] {
      return this.innerRelationships;
    },
    outerRelationshipsList(): any[] {
      return this.outerRelationships;
    },
    lookupResults(): PaginatedResponse<Character[]> | null {
      return this.lookupResultsValue;
    },
    dropdownCollapsed(): boolean {
      return this.isDropDownCollapsed;
    },
    characterColors(): any[] {
      const storedColorsArray = this.character?.info.colors || [];
      return storedColorsArray;
    },
    characterColorsEditable(): KeyedColor[] {
      return this.keyedColors || [];
    },
    characterQualities(): any[] {
      const qualities = this.character?.info.qualities;
      const qualitiesOrder = this.character?.info.qualitiesConfig;
      return formatCharacterQualitiesIn(qualities, qualitiesOrder);
    },
    characterTimeline(): any[] {
      /*
        Turns the stored data that looks like the following:
        about:
          {
            key2: value2,
            key1: value1,
          }
        aboutOrder: 
          {
            key1: { orderNum: 1 },
            key3: { orderNum: 3 },
            key2: { orderNum: 2 },
          }
        Into a sorted array of objects based on the key order
      */
      const timeline = this.character?.info.timeline;
      // const aboutOrder = this.character?.info.aboutConfig;
      return timeline;
    },
    characterAlignmentCharts(): any[] {
      const chart = this.character?.info.alignment_charts;
      return chart;
    },
    charTriviaQuestions(): any[] {
      return this.questions;
    },
    charOutfits(): any[] {
      const outfits = this.character?.info.outfits;
      return outfits;
    },
    charFav(): any[] {
      const outfits = this.character?.info.favorites;
      return outfits;
    },
    charInventory(): any[] {
      const outfits = this.character?.info.inventory;
      return outfits;
    },
    charLifeStages(): any[] {
      const outfits = this.character?.info.life_stages;
      return outfits;
    },

    charOutfitsIds(): any[] {
      const outfits = this.character?.info.outfit_ids;
      return outfits;
    },
    charFavIds(): any[] {
      const outfits = this.character?.info.favorites_ids;
      return outfits;
    },
    charInventoryIds(): any[] {
      const outfits = this.character?.info.inventory_ids;
      return outfits;
    },
    charLifeStagesIds(): any[] {
      const outfits = this.character?.info.life_stages_ids;
      return outfits;
    },
    characterStats(): any[] {
      /*
        Turns the stored data that looks like the following:
        stats:
          {
            key2: value2,
            key1: value1,
          }
        statsOrder: 
          {
            key1: { orderNum: 1 },
            key3: { orderNum: 3 },
            key2: { orderNum: 2 },
          }
        Into a sorted array of keys: ['key1', 'key2']
      */
      const stats = this.character?.info.stats;
      const statsOrder = this.character?.info.statsConfig;
      return formatStatsIn(stats, statsOrder);
    },
  },
});

export const characterEditorStore = () => {
  const store = useCharacterEditor();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
