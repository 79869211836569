<template>
  <div class="about">
    <div v-for="(item, index) of orderedAbout" :key="index">
      <div v-if="hasValue(item)" class="one-about d-flex align-items-center">
        <div class="aboutkey calced-colors">

          {{ toTitleSpaceCase(item.key) }}
          <div
            v-if="aboutConfig[item.key].id === item.id && aboutConfig[item.key].hasOwnProperty('quizId')"
            class="d-inline-block quiz"
          >
            <i class="ti-info-alt clickable-item-hov mr-1 info-icon" @mouseover="openPopup($event, item)" />
          </div>

        </div>
        <div
          class="aboutvalue dark d-flex justify-content-center flex-grow-1 flex-column"
          :style="{ border: sectionBorderAndColor }"
        >
          {{ item.value }}
        </div>
      </div>
    </div>

    <quiz-insights-modal
      :is-open="isOpenInsightModal"
      :log-id="logId"
      @close="closeInsightsModal"
      @view="closeAndOpenViewInsightModal"
    />
    <view-insight-report-modal
      :insights="quizInsights"
      :insight-index="insightIndex"
      :is-open="isOpenViewInsightModal"
      @close="closeViewInsightModal"
      @load="fetchCharProf"
    />
  </div>
</template>

<script lang="ts" setup>
import QuizTraitPopover from '../popovers/QuizTraitPopover.vue';
import QuizInsightsModal from '@/shared/modals/QuizInsightsModal.vue';
import ViewInsightReportModal from '@/shared/modals/ViewInsightReportModal.vue';
import { toTitleSpaceCase } from '@/shared/utils/string';
import { formatCharacterAboutIn } from '@/shared/utils/transform';
import { mainStore } from '@/shared/pinia-store/main';
import { popovers } from '@/shared/native';

const props = defineProps({
  about: {
    type: Object,
    default: () => ({}),
  },
  aboutConfig: {
    type: Object,
    default: () => ({}),
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
  charAuth: {
    type: Object,
    default: () => {},
  },
});

const about = toRef(props, 'about');
const charAuth = toRef(props, 'charAuth');
const aboutConfig: any = toRef(props, 'aboutConfig');
const customize = toRef(props, 'customize');
const logId = ref('');
const quizInsights = ref({});
const insightIndex = ref('');
const isOpenViewInsightModal = ref(false);
const isOpenInsightModal = ref(false);
const emits = defineEmits(['fetch']);
const hasValue = (about: any) => {
  return about.value;
};
const fetchCharProf = () => {
  emits('fetch');
};

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const { isTempDefault } = useCustTempDefault(props);

const getBackgroundColor = computed(() => {
  return isTempDefault.value && isdark.value
    ? '#010123'
    : isTempDefault.value && get(customize.value, 'data.theme.backgrounds.infoSection') === '#E6E6E6'
    ? '#FFFFFF'
    : get(customize.value, 'data.theme.backgrounds.infoSection', '#FFFFFF');
});
const defaultTextColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});
const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font', 'Roboto');
});
const closeAndOpenViewInsightModal = (val: any, index: any) => {
  quizInsights.value = val;
  insightIndex.value = index;
  closeInsightsModal();
  isOpenViewInsightModal.value = true;
};

const openPopup = async (ev: any, item: any) => {
  if (aboutConfig.value[item.key].id && item.id && aboutConfig.value[item.key].quizId !== undefined) {
    const qId = get(aboutConfig.value[item.key], 'quizId', null);
    const qName = get(aboutConfig.value[item.key], 'quizName', null);
    logId.value = get(aboutConfig.value[item.key], 'quizLogId', null);

    const popover = await popovers.open(ev, QuizTraitPopover, {
      quizId: qId,
      quizName: qName,
      charAuth: charAuth.value,
    });
    const { data } = await popover.onDidDismiss();
    if (!isEmpty(get(data, 'value')) && get(data, 'value') === 'open') {
      isOpenInsightModal.value = true;
    }
  }
};
const closeInsightsModal = () => {
  isOpenInsightModal.value = false;
};

const oneTextValueBold = computed(() => {
  return isTempDefault.value ? '100' : get(customize.value, 'data.theme.text.default.bold') ? '800' : '100';
});

const defaultTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

const sectionBorderAndColor = computed(() => {
  const color = get(customize.value, 'data.theme.text.default.color', '#000000');

  return isTempDefault.value ? 'none' : `3px solid ${color} !important`;
});
const orderedAbout = computed(() => {
  return formatCharacterAboutIn(about.value, aboutConfig.value);
});
const closeViewInsightModal = () => {
  isOpenViewInsightModal.value = false;
};
</script>

<style lang="sass" scoped>
.quiz-text
  font-size: 16px
.info-icon
  font-size: 12px
  color: v-bind(defaultTextColor) !important
.set-padding
  padding: 0px !important
.about
  .one-about
    margin-top: 10px
    .aboutvalue
      background: v-bind(getBackgroundColor)
      border-radius: 12px
      width: calc(100% - 150px)
      color: v-bind(defaultTextColor) !important
      padding: 7px 0.7rem !important
      word-break: break-word
      white-space: pre-line
      text-align: left
      
      &.calced-colors
        background: v-bind(getBackgroundColor) !important
        color: v-bind(defaultTextColor) !important
        font-weight: v-bind(oneTextValueBold) !important
        font-style: v-bind(defaultTextItalic) !important
        font-family: v-bind(defaultTextFont) !important
    @media(max-width: 500px)
      flex-direction: column
      .aboutkey, .aboutvalue
        width: 100% !important
      .aboutkey
        margin-bottom: 5px

    .aboutkey
      text-align: left
      align-self: flex-start !important
      margin-top: 9px
      line-height: 1.1rem 
      position: relative
      width: 130px
      font-weight: bold
      margin-right: 10px
      &.calced-colors
        color: v-bind(defaultTextColor) !important
        font-style: v-bind(defaultTextItalic) !important
        font-family: v-bind(defaultTextFont) !important

@media(max-width: 600px)
  .aboutkey
    margin-left: auto
    width: 120px !important
</style>
