import * as Sentry from '@sentry/vue';
import VueGtag from 'vue-gtag';
import * as amplitude from '@amplitude/analytics-browser';
import { BrowserTracing } from '@sentry/browser';
import { storage } from '@/shared/native';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';

const getAppVer = () => {
  try {
    const {
      public: { buildNumber },
    } = useRuntimeConfig();
    return `1.0${buildNumber || 0}`;
  } catch (error) {
    return '0';
  }
};

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { env, gaID, amplitudeKey, sentryDsn },
  } = useRuntimeConfig();
  if (env !== 'development') {
    const { user } = authStore();
    storage.get(constants.lsKeys.cookiePermissions).then((perms) => {
      let permissions = constants.cookiePerms;
      if (perms) permissions = JSON.parse(perms);
      if (permissions.ga) {
        try {
          nuxtApp.vueApp.use(VueGtag, {
            config: {
              id: gaID,
              params: {
                anonymize_ip: true,
              },
            },
            // bootstrap: false
          });
        } catch (e) {}
      }
      if (permissions.amp === true) {
        try {
          amplitude.init(amplitudeKey, user.value?.id, {});
        } catch (error) {}
      }
      if (permissions.sen) {
        try {
          Sentry.init({
            app: nuxtApp.vueApp,
            dsn: sentryDsn,
            release: getAppVer(),
            integrations: [
              new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.vueApp.$nuxt.$router as any),
                tracingOrigins: ['localhost', 'characterhub.com', /^\//],
              }),
            ],
            beforeSend(event: any, hint: any) {
              try {
                if (event.exception.values[0].stacktrace.frames[0].filename === `<anonymous>`) {
                  return null;
                }
              } catch (e) {}
              if (hint?.originalException === 'Timeout') return null;
              if (event?.message?.includes('Non-Error promise rejection captured with value: Timeout')) {
                return null;
              }
              if (event?.message?.includes('Non-Error promise rejection captured with value: undefined')) {
                return null;
              }
              if (event?.message?.includes('Could not fetch data from')) {
                return null;
              }
              return event;
            },
            tracesSampleRate: 0.001,
            ignoreErrors: [ // https://gist.github.com/impressiver/5092952
              "top.GLOBALS",
              "originalCreateNotification",
              "canvas.contentDocument",
              "MyApp_RemoveAllHighlights",
              "http://tt.epicplay.com",
              "Can't find variable: ZiteReader",
              "jigsaw is not defined",
              "ComboSearch is not defined",
              "http://loading.retry.widdit.com/",
              "atomicFindClose",
              "fb_xd_fragment",
              "bmi_SafeAddOnload",
              "EBCallBackMessageReceived",
              "conduitPage",
              'Script error.',
              'Network Error',
              'timeout exceeded',
              'Request aborted',
              '<anonymous>',
              'AxiosError',
              'XhrError',
              'Request failed with status',
              "adsbygoogle.push() error: All 'ins'",
              "evaluating 'v.component.subTree'",
              "Cannot read properties of null (reading 'insertBefore')",
              "Importing a module script failed.",
              "Failed to fetch dynamically imported module",
              "null is not an object (evaluating 't.parentNode.insertBefore')",
              "Unable to preload CSS for",
              "Cannot destructure property 'bum' of 'v' as it is null.",
              "Failed to fetch dynamically imported module",
              'Failed to fetch',
              'zoid destroyed all components',
              'Document is ready and element #paypal-button-container does not exist',
              'We could not retrieve data from the specified Element',
              "Failed to execute 'insertBefore'",
            ],
            denyUrls: [
              // Facebook flakiness
              /graph\.facebook\.com/i,
              // Facebook blocked
              /connect\.facebook\.net\/en_US\/all\.js/i,
              // Woopra flakiness
              /eatdifferent\.com\.woopra-ns\.com/i,
              /static\.woopra\.com\/js\/woopra\.js/i,
              // Chrome extensions
              /extensions\//i,
              /^chrome:\/\//i,
              /^chrome-extension:\/\//i,
              // Other plugins
              /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
              /webappstoolbarba\.texthelp\.com\//i,
              /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
              /tinymce/i,
            ],
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
      }
    });
  }
});
